<template>
  <div class="form__container">
    <h3 data-test-id="work-steps-heading" class="form__subtitle" v-html="$t('workSteps')" />
    <div class="row">
      <div class="col form__col">
        <p data-test-id="planned-dates-heading">
          <strong>{{ $t('plannedDates') }} </strong>
        </p>
        <p data-test-id="planned-dates-description1">{{ $t('plannedDatesDesc1') }}</p>
        <p data-test-id="planned-dates-description2">{{ $t('plannedDatesDesc2') }}</p>
        <p data-test-id="planned-dates-description3"><strong><u>{{ $t('plannedDatesDesc3') }}</u></strong></p>
      </div>
    </div>
    <building-planned-dates :planned-dates="building.plannedDates" />
    <contacts :contacts="building.contacts" />
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import BuildingPlannedDates from '@/components/building/edit/construction-site/BuildingPlannedDates'
import Contacts from '@/components/building/edit/construction-site/Contacts'

export default {
  components: { CheckboxGcr, BuildingPlannedDates, Contacts },
  props: {
    building: Object
  }
}
</script>
