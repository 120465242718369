<template>
  <div>
    <div class="row">
      <div class="form__col col-xs-auto">
        <cash-gcr
            data-test-id="sales-price"
            id="sales-price"
            :label="$t('salePrice')"
            :tooltip="$t('salePriceDesc')"
            v-model="sale.price"
            :validation-feedback="salesPriceFeedback"
        />
      </div>
    </div>
    <h3 data-test-id="contract-and-beneficiary" class="form__subtitle">{{ $t('contractAndBeneficiary') }}</h3>
    <div class="row">
      <div class="form__col col">
        <radio-group
            data-test-id="has-known-beneficiary"
            group="has-known-beneficiary"
            :options="hasKnownBeneficiaryOptions"
            v-model="sale.hasKnownBeneficiary"
            :validation-feedback="hasKnownBeneficiaryValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="form__col col">
        <land-owner-selection
            data-test-id="land-owner-type"
            :land-owner="landOwner"
            :disable-beneficiary-option="!sale.hasKnownBeneficiary"
            :contractor-name="contractorName"
            :third-party-note="$t('buildingVocationSaleThirdPartyNote', { contractorName })"
        />
      </div>
    </div>
    <sale-contract v-if="sale.hasKnownBeneficiary" :sale="sale" :land-owner-type="landOwner.type"/>
  </div>
</template>

<script>
import CashGcr from '@/components/atoms/Cash'
import RadioGroup from '@/components/atoms/RadioGroup'
import Validators from '@/_helpers/validators'
import LandOwnerSelection from '@/components/building/edit/sales/LandOwnerSelection'
import SaleContract from '@/components/building/edit/sales/SaleContract'

export default {
  components: { CashGcr, RadioGroup, LandOwnerSelection, SaleContract },
  props: {
    sale: Object,
    landOwner: Object,
    contractorName: String
  },
  data() {
    return {
      hasKnownBeneficiaryOptions: [
        { label: this.$t('beneficiaryAlreadyKnown'), value: true },
        { label: this.$t('beneficiaryToBeDetermined'), value: false }
      ]
    }
  },
  computed: {
    salesPriceFeedback() {
      return Validators.required(this.sale.price, this.$t('salePriceRequired'))
    },
    hasKnownBeneficiaryValidationFeedback() {
      return Validators.required(this.sale.hasKnownBeneficiary, this.$t('beneficiaryKnownRequired'))
    },
    landOwnerIsThirdParty() {
      return this.landOwner.type === 'THIRD_PARTY'
    }
  },
  methods: {
    clearContract() {
      this.sale.contractNumber = null
      this.sale.plannedDeliveryDate = null
      this.sale.scopeOfWork = null
    }
  },
  watch: {
    'sale.hasKnownBeneficiary'(newValue) {
      if (!newValue) this.clearContract()
    }
  }
}
</script>
