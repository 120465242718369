<template>
  <div class="custom-control custom-checkbox">
    <input
      ref="input"
      :id="id"
      class="custom-control-input"
      type="checkbox"
      :checked="checked"
      v-bind="$attrs"
      v-on="listeners"
    />
    <label class="custom-control-label" :for="id">
      <slot>
        {{ label }}
      </slot>
      <info v-if="tooltip" :tooltip="tooltip" @click.native.prevent />
    </label>
    <div v-if="validationFeedbackVisible" class="invalid-feedback">{{ validationFeedback }}</div>
  </div>
</template>

<script>
import Info from '@/components/atoms/Info'

export default {
  components: { Info },
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    checked: Boolean,
    label: String,
    tooltip: {},
    id: {
      type: String,
      default() {
        return `checkbox-${this._uid}`
      }
    },
    validationFeedback: {
      type: String,
      default: () => ''
    },
    showValidationFeedback: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        change: event => this.$emit('change', event.target.checked)
      }
    },
    validationFeedbackVisible() {
      return this.showValidationFeedback && this.validationFeedback
    }
  },
  watch: {
    validationFeedback(message) {
      this.$refs.input.setCustomValidity(message)
    }
  },
  mounted() {
    this.$refs.input.setCustomValidity(this.validationFeedback)
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.custom-checkbox {
  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-color: #639113;
      border-radius: 4px;
    }
  }
}

.was-validated .custom-control-input:valid ~ .custom-control-label {
  color: gray('gray-750');
}

.invalid-feedback {
  display: none;
}

.was-validated .invalid-feedback {
  display: block;
}
</style>

