<template>
  <div>
    <h3 data-test-id="contacts-heading" class="form__subtitle" v-html="$t('contacts')"/>
    <div class="row">
      <div class="col form__col">
        <p data-test-id="contacts-description">{{ $t('contactsDescription') }}</p>
      </div>
    </div>
    <div class="row" v-if="!editMode">
      <div class="col-12 form__col">
        <contact-list
          :contacts="contacts"
          @edit="editAt"
          @remove="removeAt"
        />
      </div>
      <div class="col form__col col-flex-justify-end">
        <button-gcr
          data-test-id="add-contact"
          type="button"
          btn-class="btn-primary btn-sm"
          label="contactAdd"
          @btnclick="newContact"
        />
      </div>
    </div>
    <contact-form
      v-else
      :contact="contactToEdit"
      @save="saveEdited"
      @cancel="cancelEdit"
    />
  </div>
</template>

<script>
import ButtonGcr from '@/components/atoms/Button'
import ContactForm from '@/components/building/edit/construction-site/ContactForm'
import ContactList from '@/components/building/edit/construction-site/ContactList'

export default {
  components: { ButtonGcr, ContactForm, ContactList },
  props: {
    contacts: Array
  },
  data() {
    return {
      editMode: false,
      toEditIndex: null
    }
  },
  computed: {
    contactToEdit() {
      return this.contacts[this.toEditIndex]
    }
  },
  methods: {
    newContact() {
      this.toEditIndex = this.contacts.length
      this.editMode = true
    },
    saveEdited(contact) {
      this.editMode = false
      this.$set(this.contacts, this.toEditIndex, contact)
    },
    cancelEdit() {
      this.editMode = false
    },
    editAt(index) {
      this.toEditIndex = index
      this.editMode = true
    },
    removeAt(index) {
      this.contacts.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.col-flex-justify-end {
  display: flex;
  justify-content: flex-end;
}
</style>
