<template>
  <div>
    <div class="row">
      <div class="form__col col col-xs-6">
        <date-gcr
          data-test-id="planned-start"
          id="planned-start"
          label="plannedStart"
          v-model="plannedDates.start"
          :validation-feedback="plannedStartValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="form__col col col-xs-6">
        <date-gcr
          data-test-id="planned-foundation-backfilling"
          id="planned-foundation-backfilling"
          label="plannedFoundationBackfilling"
          v-model="plannedDates.foundationBackfilling"
          :validation-feedback="plannedFoundationBackfillingValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="form__col col col-xs-6">
        <date-gcr
          data-test-id="planned-inside-wall-coverings"
          id="planned-inside-wall-coverings"
          label="plannedInsideWallCoverings"
          v-model="plannedDates.insideWallCoverings"
          :validation-feedback="plannedInsideWallCoveringsValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="form__col col col-xs-6">
        <date-gcr
          data-test-id="planned-wall-coverings"
          id="planned-wall-coverings"
          label="plannedWallCoverings"
          v-model="plannedDates.wallCoverings"
          :validation-feedback="plannedWallCoveringsValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="form__col col col-xs-6">
        <date-gcr
          data-test-id="planned-end"
          id="planned-end"
          label="plannedEnd"
          v-model="plannedDates.end"
          :validation-feedback="plannedEndValidationFeedback"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DateGcr from '@/components/atoms/Date'
import Validators from '@/_helpers/validators'

export default {
  components: { DateGcr },
  props: {
    plannedDates: Object
  },
  computed: {
    plannedStartValidationFeedback() {
      return Validators.optionalDateIsAfterOrEqual(this.plannedDates.start, '2015-01-01', this.$t('invalidPlannedDate2015'))
    },
    plannedFoundationBackfillingValidationFeedback() {
      return Validators.optionalDateIsAfterOrEqual(this.plannedDates.foundationBackfilling, '2015-01-01', this.$t('invalidPlannedDate2015')) ||
        Validators.optionalDateIsBetweenInclusive(this.plannedDates.foundationBackfilling, this.plannedDates.start, this.plannedDates.end, this.$t('invalidFoundationBackfilling'))
    },
    plannedWallCoveringsValidationFeedback() {
      return Validators.optionalDateIsAfterOrEqual(this.plannedDates.wallCoverings, '2015-01-01', this.$t('invalidPlannedDate2015')) ||
        Validators.optionalDateIsBetweenInclusive(this.plannedDates.wallCoverings, this.plannedDates.start, this.plannedDates.end, this.$t('invalidWallCoverings')) ||
        Validators.optionalDateIsBeforeOrEqual(this.plannedDates.foundationBackfilling, this.plannedDates.wallCoverings, this.$t('invalidWallCoveringsAfterFoundation'))
    },
    plannedInsideWallCoveringsValidationFeedback() {
      return Validators.optionalDateIsAfterOrEqual(this.plannedDates.insideWallCoverings, '2015-01-01', this.$t('invalidPlannedDate2015')) ||
        Validators.optionalDateIsBetweenInclusive(this.plannedDates.insideWallCoverings, this.plannedDates.start, this.plannedDates.end, this.$t('invalidInsideWallCoverings'))
    },
    plannedEndValidationFeedback() {
      return Validators.optionalDateIsAfterOrEqual(this.plannedDates.end, '2015-01-01', this.$t('invalidPlannedDate2015')) ||
        Validators.optionalDateIsBeforeOrEqual(this.plannedDates.start, this.plannedDates.end, this.$t('invalidPlannedEnd'))
    }
  }
}
</script>
