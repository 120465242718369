<template>
  <div>
    <building-details-form :building="building" :project-city="projectCity" :project-type="projectType" :project-id="projectId" class="form__container" />
    <dwelling-unit-single-address-form v-if="isSingleAddress" :project-city="projectCity" :dwelling-unit="firstDwellingUnit" />
    <dwelling-unit-multiple-address-form v-else :building="building" />
  </div>
</template>

<script>
import BuildingDetailsForm from '@/components/building/edit/details/BuildingDetailsForm'
import DwellingUnitSingleAddressForm from '@/components/building/edit/details/DwellingUnitSingleAddressForm'
import DwellingUnitMultipleAddressForm from '@/components/building/edit/details/DwellingUnitMultipleAddressForm'
import { buildingDetailsChanged, synchronizeAddresses } from '@/_helpers/buildings'

export default {
  components: { BuildingDetailsForm, DwellingUnitSingleAddressForm, DwellingUnitMultipleAddressForm },
  props: {
    building: Object,
    projectCity: String,
    projectType: String,
    projectId: String
  },
  computed: {
    isSingleAddress() {
      return this.building.dwellingUnits.length === 1
    },
    firstDwellingUnit() {
      return this.building.dwellingUnits[0]
    }
  },
  watch: {
    'building.type'() {
      buildingDetailsChanged(this.building)
    },
    'building.dwellingUnitCount'() {
      buildingDetailsChanged(this.building)
    },
    'building.synchronizeAddresses'() {
      synchronizeAddresses(this.building)
    },
    firstDwellingUnit: {
      deep: true,
      handler() {
        synchronizeAddresses(this.building)
      }
    }
  }
}
</script>
