<template>
  <div class="a-step-number" v-on:click="onclick" :class="{ 'a-step-number--active': active }">
    <span class="a-step-number__number">{{ number }}</span>
    <span class="a-step-number__label" v-if="label">{{ $t(label) }}</span>
    <span class="a-step-number__separator" v-if="separator"></span>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      default() {
        return 1
      }
    },
    active: {
      default() {
        return false
      }
    },
    label: {
      default() {
        return false
      }
    },
    separator: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  methods: {
    onclick(evt) {}
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.a-step-number {
  min-width: 1px;
  display: flex;
  align-items: center;

  &__number {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 25%;
    border: 3px solid gray('gray-200');
    text-align: center;
    line-height: 35px;
    font-size: 24px;
    font-weight: 500; //font-weight: 400;;
    color: gray('gray-200');
  }
  &__label {
    display: block;
    font-size: 15px;
    color: gray('gray-750');
    font-weight: 500;
    margin: 0 0 0 10px;
    white-space: nowrap;
  }
  &__separator {
    display: block;
    background-color: gray('gray-200');
    height: 2px;
    flex-grow: 1;
    margin-right: 10px;
    margin-left: 10px;
  }
  &:active,
  &--active {
    .a-step-number__number {
      color: theme-color('primary');
      border-color: theme-color('primary');
    }
  }
}
</style>
