<template>
  <div class="m-confirmation-popover">
    <v-popover placement="top" container="#app" boundariesElement="window" offset="0">
      <button-gcr
        v-bind="$attrs"
        :btnClass="btnClass"
        type="button"
        :inline="false"
        :group="false"
        v-tooltip="{ content: $t(btnTooltipText), placement: 'top' }"
      />
      <template slot="popover">
        <div class="m-confirmation-popover__content">
          <h3 class="m-confirmation-popover__title" v-if="confirmTitle">{{ $t(this.confirmTitle) }}</h3>
          <p class="m-confirmation-popover__text" v-if="confirmText" v-html="$t(confirmText)"/>
        </div>
        <div class="m-confirmation-popover__footer" v-if="cancelButtonText && acceptButtonText">
          <button class="btn btn-outline-gray btn-sm m-confirmation-popover__btn" v-if="cancelButtonText" v-close-popover>
            {{ $t(this.cancelButtonText) }}
          </button>
          <button
            class="btn btn-primary btn-sm m-confirmation-popover__btn"
            v-if="acceptButtonText"
            :disabled="acceptButtonDisabled"
            v-close-popover
            v-on:click="onclick"
          >
            {{ $t(this.acceptButtonText) }}
          </button>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>
import ButtonGcr from '@/components/atoms/Button'
import { VPopover } from 'v-tooltip'

require('../../utilities/tooltip')

export default {
  data() {
    return {}
  },
  inheritAttrs: false,
  components: { ButtonGcr, VPopover },
  props: {
    btnTooltipText: {
      type: String
    },
    btnClass: {},
    confirmTitle: {
      type: String
    },
    confirmText: {
      type: String
    },
    acceptButtonText: {
      type: String,
      default: function(value) {
        return value ? value : false
      }
    },
    acceptButtonDisabled: {
      type: Boolean,
      default: false
    },
    cancelButtonText: {
      type: String,
      default: function(value) {
        return value ? value : false
      }
    }
  },
  methods: {
    onclick(evt) {
      this.$emit('confirm', evt)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.m-confirmation-popover {
  position: relative;
  display: inline-block;
  &__title {
    font-size: 17px;
    font-weight: 500;
  }
  &__text {
  }
  &__content {
    max-width: calc(100vw - 60px);
  }
  &__footer {
    max-width: calc(100vw - 60px);
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;

    :last-child {
      margin-right: 0;
    }
  }
  &__btn {
    margin-right: 15px;
  }

  @media screen and (min-width: breakpoint-min('md')) {
    &__title {
      max-width: 400px;
    }
    &__content {
      max-width: 400px;
    }
  }
}
</style>
