<template>
  <land-owner-selection
    data-test-id="land-owner-type"
    :land-owner="landOwner"
    disable-beneficiary-option
    :contractor-name="contractorName"
    :third-party-note="$t('buildingVocationRentalThirdPartyNote', { contractorName })"
  />
</template>

<script>
import LandOwnerSelection from '@/components/building/edit/sales/LandOwnerSelection'

export default {
  components: { LandOwnerSelection },
  props: {
    landOwner: Object,
    contractorName: String
  },
  computed: {
    landOwnerIsThirdParty() {
      return this.landOwner.type === 'THIRD_PARTY'
    }
  }
}
</script>
