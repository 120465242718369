<template>
  <div>
    <div class="row">
      <strong class="section-title form__col col-12" data-test-id="framework-title">{{ $t('framework') }} </strong>
      <div class="col-sm-8">
        <select-gcr
            data-test-id="framework"
            :options="options"
            v-model="technicalFeatures.mainFramework"
        />
      </div>
    </div>
    <div v-if="isUnconventional" class="form__col col-12 input-desc" data-test-id="unconventional-info">
      {{ $t('unconventionalInfo') }}
    </div>
  </div>
</template>

<script>
import SelectGcr from '@/components/atoms/Select'

export default {
  components: { SelectGcr },
  props: {
    technicalFeatures: Object
  },
  data() {
    return {
      options: [
        { text: 'woodFrame', value: 'woodFrame' },
        { text: 'steelFrame', value: 'steelFrame' },
        { text: 'concreteFrame', value: 'concreteFrame' },
        { text: 'modularHome', value: 'modularHome' },
        { text: 'logHouse', value: 'logHouse' },
        { text: 'unconventional', value: 'unconventional' },
        { text: 'icfHome', value: 'icfHome' }
      ]
    }
  },
  computed: {
    isUnconventional() {
      return this.technicalFeatures.mainFramework === 'unconventional'
    }
  }
}
</script>
