<template>
  <form-gcr :title="title" :cancel-url="cancelUrl">
    <template #buttons>
      <slot name="actions"></slot>
      <button-gcr
        v-if="hasPreviousStep"
        data-test-id="previous-step"
        type="button"
        btnClass="btn-primary btn-sm btn-arrow-back form__header__btn"
        label="previous"
        :group=false
        @btnclick="previousStep()"
      />
      <button-gcr
        v-if="hasNextStep"
        data-test-id="next-step"
        type="button"
        btnClass="btn-primary btn-sm btn-arrow form__header__btn"
        label="next"
        :group=false
        @btnclick="nextStep()"
      />
      <button-gcr
        v-else
        data-test-id="submit-step"
        type="button"
        btnClass="btn-primary btn-sm form__header__btn"
        :label="submitText"
        :loading="submitting"
        :group=false
        :disabled="readonly"
        @btnclick="submit"
      />
    </template>

    <form-steps :activeStep="activeStep" :lastValidatedStep="lastValidatedStep">
      <slot></slot>
    </form-steps>

  </form-gcr>
</template>

<script>
import FormGcr from '@/components/molecules/Form'
import FormSteps from '@/components/molecules/FormSteps'
import ButtonGcr from '@/components/atoms/Button'

export default {
  components: {
    FormGcr,
    FormSteps,
    ButtonGcr
  },
  props: {
    title: String,
    submitText: String,
    cancelUrl: Object,
    submitting: Boolean,
    readonly: Boolean
  },
  data() {
    return {
      activeStep: 1,
      lastValidatedStep: 0
    }
  },
  computed: {
    hasPreviousStep() {
      return this.activeStep > 1
    },
    hasNextStep() {
      return this.activeStep < this.$slots.default.length
    }
  },
  methods: {
    nextStep() {
      if (this.checkValidity()) {
        this.activeStep++
      }
    },
    previousStep() {
      this.activeStep -= 1
    },
    submit() {
      if (this.checkValidity())
        this.$emit('submit')
    },
    checkValidity() {
      this.lastValidatedStep = this.activeStep

      const validity = this.$el.checkValidity()
      if (!validity) this.showFirstInvalid()

      return validity
    },
    showFirstInvalid() {
      this.$el
        .querySelector(':invalid')
        .scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  },
  watch: {
    activeStep() {
      window.scrollTo(0, 0)
    }
  }
}
</script>
