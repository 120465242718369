<template>
  <div class="form__container">
    <h3 data-test-id="technical-features-heading" class="form__subtitle">{{ $t('technicalFeatures') }}</h3>
    <div class="row">
      <div class="col form__col" data-test-id="technical-features-description" v-html="$t('technicalFeaturesDescription')" />
    </div>
    <technical-features-foundation :technical-features="technicalFeatures" class="section" />
    <technical-features-framework v-if="technicalFeatures.framework" :technical-features="technicalFeatures.framework" class="section" />
    <technical-features-floor v-if="technicalFeatures.floor" :technical-features="technicalFeatures.floor" class="section" />
    <technical-features-roof v-if="technicalFeatures.roof" :technical-features="technicalFeatures.roof" class="section" />
    <technical-features-siding v-if="technicalFeatures.siding" :technical-features="technicalFeatures.siding" class="section" />
    <technical-features-garage v-if="technicalFeatures.garage" :technical-features="technicalFeatures.garage" class="section" />
    <radio-group
      data-test-id="has-other-technical-features"
      group="has-other-technical-features"
      class="question"
      :label="$t('hasOtherTechnicalFeatures')"
      :inline=true
      :options="yesNoOptions"
      v-model="technicalFeatures.hasOtherTechnicalFeatures"
      :show-validation-feedback=false
      :validation-feedback="hasOtherTechnicalFeaturesValidationFeedback"
    />
    <template v-if="technicalFeatures.hasOtherTechnicalFeatures">
      <technical-features-structural-concrete v-if="technicalFeatures.structuralConcrete" :technical-features="technicalFeatures.structuralConcrete" class="section" />
      <technical-features-performance v-if="technicalFeatures.performance" :technical-features="technicalFeatures.performance" class="section" />
      <other-technical-features v-if="technicalFeatures.other" :technical-features="technicalFeatures.other" class="section" />
    </template>
    <h3 data-test-id="signature-heading" class="form__subtitle">{{ $t('signature') }}</h3>
    <div class="row">
      <div class="form__col col">
        <checkbox-gcr
          data-test-id="signature"
          :label="$t('legalTextSignatureBuilding')"
          v-model="signature"
          :validation-feedback="signatureValidationFeedback"
          :show-validation-feedback=false
        />
      </div>
    </div>
  </div>
</template>

<script>

import TechnicalFeaturesFoundation from '@/components/building/edit/technical-features/TechnicalFeaturesFoundation'
import TechnicalFeaturesFramework from '@/components/building/edit/technical-features/TechnicalFeaturesFramework'
import TechnicalFeaturesFloor from '@/components/building/edit/technical-features/TechnicalFeaturesFloor'
import TechnicalFeaturesRoof from '@/components/building/edit/technical-features/TechnicalFeaturesRoof'
import TechnicalFeaturesGarage from '@/components/building/edit/technical-features/TechnicalFeaturesGarage'
import TechnicalFeaturesSiding from '@/components/building/edit/technical-features/TechnicalFeaturesSiding'
import TechnicalFeaturesStructuralConcrete from '@/components/building/edit/technical-features/TechnicalFeaturesStructuralConcrete'
import TechnicalFeaturesPerformance from '@/components/building/edit/technical-features/TechnicalFeaturesPerformance'
import OtherTechnicalFeatures from '@/components/building/edit/technical-features/OtherTechnicalFeatures'
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import RadioGroup from '@/components/atoms/RadioGroup'
import Validators from '@/_helpers/validators'
import { defaultOtherTechnicalFeatures, defaultPerformanceFeatures, defaultStructuralConcreteFeatures } from '@/_models/technical_features'

export default {
  components: {
    TechnicalFeaturesFoundation,
    TechnicalFeaturesFramework,
    TechnicalFeaturesFloor,
    TechnicalFeaturesRoof,
    TechnicalFeaturesGarage,
    TechnicalFeaturesSiding,
    TechnicalFeaturesStructuralConcrete,
    TechnicalFeaturesPerformance,
    OtherTechnicalFeatures,
    CheckboxGcr,
    RadioGroup
  },
  props: {
    technicalFeatures: Object
  },
  data() {
    return {
      yesNoOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false }
      ],
      signature: false
    }
  },
  computed: {
    signatureValidationFeedback() {
      return Validators.test(this.signature, this.$t('signatureRequired'))
    },
    hasOtherTechnicalFeatures() {
      return this.technicalFeatures.hasOtherTechnicalFeatures
    },
    hasOtherTechnicalFeaturesValidationFeedback() {
      return Validators.required(this.technicalFeatures.hasOtherTechnicalFeatures, this.$t('hasOtherTechnicalFeaturesRequired'))
    }
  },
  watch: {
    hasOtherTechnicalFeatures(hasOther) {
      if (!hasOther) {
        this.technicalFeatures.structuralConcrete = this.technicalFeatures.structuralConcrete && defaultStructuralConcreteFeatures()
        this.technicalFeatures.performance = this.technicalFeatures.performance && defaultPerformanceFeatures()
        this.technicalFeatures.other = defaultOtherTechnicalFeatures()
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../../utilities/config';

.question {
  border-bottom: 4px solid gray('gray-150');
  padding-bottom: 8px;
  margin-top: 25px;
  margin-bottom: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .radio-group-label {
    font-size: 21px;
    font-weight: 500;
    color: gray('gray-700');
  }
}

.section {
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 6px;
  background-color: gray('gray-125');

  .radio-group {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .radio-group-label {
      font-size: unset;
      font-weight: unset;
    }
  }
}

.section-title {
  font-size: 18px;
  font-weight: 500;
  color: gray('gray-700');
  margin-top: 0;
  margin-bottom: 20px;
}
</style>
