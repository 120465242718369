<template>
  <div>
    <div class="row">
      <strong class="section-title form__col col" data-test-id="other-technical-features-title">{{ $t('otherTechnicalFeaturesTitle') }}</strong>
    </div>
    <div class="row">
      <div class="col-12 col-xs-auto">
        <checkbox-gcr
          data-test-id="pool"
          group="pool"
          :label="$t('indoorOrTerracePool')"
          v-model="technicalFeatures.indoorOrTerracePool"
        />
        <checkbox-gcr
          data-test-id="fire-sprinkler"
          :label="$t('fireSprinkler')"
          v-model="technicalFeatures.fireSprinkler"
        />
        <checkbox-gcr
          data-test-id="elevator"
          :label="$t('elevator')"
          v-model="technicalFeatures.elevator"
        />
        <checkbox-gcr
          data-test-id="shared-ventilation"
          v-model="technicalFeatures.sharedVentilation"
          :tooltip="$t('sharedVentilationInfo')"
          :label="$t('sharedVentilation')" />
      </div>
      <div class="col-12 col-xs-auto">
        <checkbox-gcr
          data-test-id="shared-civil-infrastructure"
          v-model="technicalFeatures.sharedCivilInfrastructure"
          :tooltip="$t('sharedCivilInfrastructureInfo')"
          :label="$t('sharedCivilInfrastructure')" />
        <checkbox-gcr
          data-test-id="tiny-home"
          :label="$t('tinyHome')"
          :tooltip="$t('tinyHomeInfo')"
          v-model="technicalFeatures.tinyHome"
        />
        <checkbox-gcr
          data-test-id="mixed-use-building"
          v-model="technicalFeatures.mixedUseBuilding"
          :tooltip="$t('mixedUseBuildingInfo')"
          :label="$t('mixedUseBuilding')" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <text-option
          data-test-id="other-technical-features"
          :label="$t('otherTechnicalFeatures')"
          :placeholder="$t('otherTechnicalFeaturesPlaceholder')"
          v-model="technicalFeatures.otherTechnicalFeatures" />
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import TextOption from '@/components/atoms/TextOption'

export default {
  components: { CheckboxGcr, TextOption },
  props: {
    technicalFeatures: Object
  }
}
</script>
