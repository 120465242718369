<template>
  <div>
    <h3 data-test-id="dwelling-unit-single-address-heading" class="form__subtitle">{{ $t('dwellingUnitSingleAddressHeader') }}</h3>

    <div class="row">
      <div class="form__col col-sm-2">
        <input-gcr data-test-id="dwelling-unit-single-address-building-number"
                   label="dwellingUnitAddressBuildingNumber"
                   v-model="dwellingUnit.buildingNumber" />
      </div>

      <div class="form__col col-sm-2">
        <input-gcr data-test-id="dwelling-unit-single-address-apartment"
                   label="dwellingUnitAddressApartment"
                   v-model="dwellingUnit.apartment" />
      </div>

      <div class="form__col col-sm-8">
        <input-gcr data-test-id="dwelling-unit-single-address-street"
                   label="dwellingUnitAddressStreet"
                   v-model.trim="dwellingUnit.street" />
      </div>
    </div>

    <div class="row">
      <div class="form__col col-sm-8">
        <input-gcr data-test-id="dwelling-unit-single-address-city"
                   label="dwellingUnitAddressCity"
                   v-model="projectCity"
                   :disabled=true />
      </div>

      <div class="form__col col-sm-4">
        <input-gcr data-test-id="dwelling-unit-single-address-postal-code"
                   label="dwellingUnitAddressPostalCode"
                   v-model="dwellingUnit.postalCode"
                   inputmask="A#A #A#"
                   :validation-feedback="postalCodeValidationFeedback" />
      </div>
    </div>
  </div>
</template>

<script>
import InputGcr from '@/components/atoms/Input'
import Validators from '@/_helpers/validators'

export default {
  components: { InputGcr },
  props: {
    dwellingUnit: Object,
    projectCity: String
  },
  computed: {
    postalCodeValidationFeedback() {
      if (!this.dwellingUnit.postalCode) return ''
      return Validators.postalCode(this.dwellingUnit.postalCode, this.$t('dwellingUnitAddressPostalCodeFormat'))
    }
  }
}
</script>
