<template>
  <div class="line">
    <fieldset class="form-group">
      <legend class="col-form-label-sm" data-test-id="condo-sale-line-label" v-html="label" />
    </fieldset>
    <div class="row">
      <div class="form__col col-xs-8 col-xl">
        <input-gcr data-test-id="condo-sale-line-lot-number"
                   v-model="dwellingUnit.lotNumber"
                   label="condoSaleLotNumber"
                   optional />
      </div>
      <div class="form__col col-xs-5 col-xl-4">
        <cash-gcr data-test-id="condo-sale-line-sale-price"
                  v-model="dwellingUnit.sale.price"
                  :label="$t('condoSalePrice')"
                  :tooltip="$t('condoSalePriceDesc')"
                  :validation-feedback="salePriceValidationFeedback" />
      </div>
      <div class="form__col col-xs-5 col-xl-3">
        <select-gcr data-test-id="condo-sale-line-beneficiary-known"
                    label="condoSaleHasKnownBeneficiary"
                    :empty-value=false
                    :options="beneficiaryKnownOptions"
                    v-model="dwellingUnit.sale.hasKnownBeneficiary" />
      </div>
    </div>
    <div v-if="hasKnownBeneficiary" class="row">
      <div class="form__col col-xs-8 col-lg-7 col-xl-6">
        <date-gcr data-test-id="condo-sale-line-planned-delivery-date"
                  label="contractPlannedDeliveryDate"
                  v-model="dwellingUnit.sale.plannedDeliveryDate"
                  optional />
      </div>
      <div class="form__col col-xs-10 col-lg-8 col-xl-6">
        <input-gcr data-test-id="condo-sale-line-contract-number"
                   v-model="dwellingUnit.sale.contractNumber"
                   label="contractNumberTypePreliminary"
                   :tooltip="$t('contractNumberNote')"
                   optional />
      </div>
    </div>
  </div>
</template>

<script>
import InputGcr from '@/components/atoms/Input'
import { shortAddress } from '@/_helpers/address'
import CashGcr from '@/components/atoms/Cash'
import SelectGcr from '@/components/atoms/Select'
import DateGcr from '@/components/atoms/Date'
import Validators from '@/_helpers/validators'

export default {
  components: { InputGcr, CashGcr, SelectGcr, DateGcr },
  data() {
    return {
      beneficiaryKnownOptions: [
        { text: 'condoSaleBeneficiaryToBeDetermined', value: false },
        { text: 'condoSaleBeneficiaryAlreadyKnown', value: true }
      ]
    }
  },
  props: {
    index: Number,
    dwellingUnit: Object
  },
  computed: {
    salePriceValidationFeedback() {
      return Validators.required(this.dwellingUnit.sale.price, this.$t('salePriceRequired'))
    },
    label() {
      return this.$t('condoSaleLabel', { index: this.index, address: shortAddress(this.dwellingUnit) })
    },
    hasKnownBeneficiary() {
      return this.dwellingUnit.sale.hasKnownBeneficiary
    }
  },
  watch: {
    hasKnownBeneficiary(newValue) {
      if (!newValue) {
        this.dwellingUnit.sale.plannedDeliveryDate = null
        this.dwellingUnit.sale.contractNumber = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../utilities/config';

fieldset {
  border-top: 2px solid theme-color('gray');
  margin: 0;
  padding-left: 5px
}

legend {
  margin-left: 4px;
  margin-bottom: 0;
  width: auto;
  padding: 0 5px 0 5px;
}

.line {
  padding: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 6px;
  background-color: gray('gray-125');
}

</style>
