<template>
  <div class="form__container">
    <h3 data-test-id="building-description" class="form__subtitle">{{ $t('buildingDescription') }}</h3>
    <div v-if="showVocation" class="row">
      <div class="form__col col">
        <radio-group
          data-test-id="is-rental"
          group="is-rental"
          :options="vocationOptions"
          :label="$t('buildingVocation')"
          v-model="building.isRental"
          inline
        />
        <div
          v-if="building.isRental"
          class="form__col col-12 input-desc"
          data-test-id="rental-note"
          v-html="$t('buildingVocationRentalNote', { contractorName })"
        />
      </div>
    </div>
    <rental v-if="building.isRental" :land-owner="building.landOwner" :contractor-name="contractorName" />
    <sale v-else :sale="building.sale" :land-owner="building.landOwner" :contractor-name="contractorName" />
  </div>
</template>

<script>

import RadioGroup from '@/components/atoms/RadioGroup'
import Rental from '@/components/building/edit/sales/Rental'
import Sale from '@/components/building/edit/sales/Sale'
import { synchronizeOwnership } from '@/_helpers/buildings'

export default {
  components: { RadioGroup, Sale, Rental },
  props: {
    building: Object,
    projectType: String,
    contractorName: String
  },
  data() {
    return {
      vocationOptions: [
        { label: this.$t('buildingVocationSale'), value: false },
        { label: this.$t('buildingVocationRental'), value: true }
      ]
    }
  },
  computed: {
    showVocation() {
      return this.projectType !== 'HORIZONTAL_CO_OWNERSHIP'
    }
  },
  watch: {
    'building.isRental'() {
      synchronizeOwnership(this.building)
    }
  }
}
</script>
