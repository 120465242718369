<template>
  <div>
    <h3 data-test-id="dwelling-unit-multiple-address-heading" class="form__subtitle">{{ $t('dwellingUnitMultipleAddressHeader') }}</h3>
    <div class="row">
      <div data-test-id="dwelling-unit-address-note" class="col input-desc input-desc__bolder" v-html="$t('dwellingUnitAddressNote')" />
    </div>
    <div class="row">
      <div class="form__col col">
        <checkbox-gcr
          data-test-id="dwelling-unit-multiple-address-synchronize"
          :label="sameAddressLabel"
          v-model="building.synchronizeAddresses" />
      </div>
    </div>
    <dwelling-unit-address-line
      v-for="(dwellingUnit, index) in building.dwellingUnits"
      :key="index"
      :index="index + 1"
      :dwelling-unit="dwellingUnit"
      :building-number-readonly="buildingNumberReadonly(index)"
      :street-readonly="readonlyAddress(index)"
      :postal-code-readonly="readonlyAddress(index)"
    />
  </div>
</template>

<script>
import DwellingUnitAddressLine from '@/components/building/edit/details/DwellingUnitAddressLine'
import CheckboxGcr from '@/components/atoms/CheckboxNew'

export default {
  components: { DwellingUnitAddressLine, CheckboxGcr },
  props: {
    building: Object
  },
  computed: {
    sameAddressLabel() {
      return this.building.type === 'MULTICONDOS' ?
        this.$t('dwellingUnitMultipleAddressSyncCoOwnership') :
        this.$t('dwellingUnitMultipleAddressSyncSoleOwnership')
    }
  },
  methods: {
    buildingNumberReadonly(index) {
      return index !== 0 && this.building.synchronizeAddresses && this.building.type === 'MULTICONDOS'
    },
    readonlyAddress(index) {
      return index !== 0 && this.building.synchronizeAddresses
    }
  }
}
</script>