export function applyScopeOfWork(features, scope) {
  scope = scope || allScopeOfWork

  features.foundation = getFoundationFeatures(features, scope)
  features.structuralConcrete = getStructuralConcreteFeatures(features, scope)
  features.framework = getFrameworkFeatures(features, scope)
  features.roof = getRoofFeatures(features, scope)
  features.floor = getFloorFeatures(features, scope)
  features.garage = getGarageFeatures(features, scope)
  features.siding = getSidingFeatures(features, scope)
  features.performance = getPerformanceFeatures(features, scope)
}

export function defaultTechnicalFeatures() {
  return {
    existingFoundation: null,
    foundation: defaultFoundationFeatures(),
    framework: defaultFrameworkFeatures(),
    floor: defaultFloorFeatures(),
    roof: defaultRoofFeatures(),
    siding: defaultSidingFeatures(),
    garage: defaultGarageFeatures(),
    hasOtherTechnicalFeatures: null,
    structuralConcrete: defaultStructuralConcreteFeatures(),
    performance: defaultPerformanceFeatures(),
    other: defaultOtherTechnicalFeatures()
  }
}

export function defaultFoundationFeatures() {
  return {
    foundationDefaults: true,
    concreteFoundation: true,
    groundSlab: false,
    foundationIcf: false,
    foundationPiles: false,
    foundationOther: null
  }
}

export function defaultFrameworkFeatures() {
  return { mainFramework: 'woodFrame' }
}

export function defaultRoofFeatures() {
  return {
    roofDefaults: true,
    pitchedRoof: true,
    flatRoof: false,
    terraceRoof: false,
    greenRoof: false
  }
}

export function defaultFloorFeatures() {
  return { floorJoist: 'spacing400mmOrLess' }
}

export function defaultGarageFeatures() {
  return {
    garage: null,
    garageCommonAccessPath: null,
    structuralConcreteGarageSlab: null,
    sharedUsageGarage: null
  }
}

export function defaultSidingFeatures() {
  return {
    masonry: false,
    lightCoating: false,
    acrylicCoating: false,
    otherSiding: null
  }
}

export function defaultStructuralConcreteFeatures() {
  return {
    livingSpaceUnderConcreteBalcony: null,
    retainingWall: null,
    otherStructuralConcreteStructure: null
  }
}

export function defaultPerformanceFeatures() {
  return {
    novoclimat: null,
    leed: null,
    netZero: null,
    passiveHouse: null
  }
}

export function defaultOtherTechnicalFeatures() {
  return {
    indoorOrTerracePool: null,
    fireSprinkler: null,
    elevator: null,
    sharedVentilation: null,
    sharedCivilInfrastructure: null,
    tinyHome: null,
    mixedUseBuilding: null,
    otherTechnicalFeatures: null
  }
}

function getFoundationFeatures(features, scope) {
  if (!requiredScopeForFoundation(scope)) return null
  return features.foundation || defaultFoundationFeatures()
}

function getStructuralConcreteFeatures(features, scope) {
  if (!requiredScopeForStructuralConcrete(scope)) return null
  return features.structuralConcrete || defaultStructuralConcreteFeatures()
}

function getFrameworkFeatures(features, scope) {
  if (!requiredScopeForFramework(scope)) return null
  return features.framework || defaultFrameworkFeatures()
}

function getRoofFeatures(features, scope) {
  if (!requiredScopeForRoof(scope)) return null
  return features.roof || defaultRoofFeatures()
}

function getFloorFeatures(features, scope) {
  if (!requiredScopeForFloor(scope)) return null
  return features.floor || defaultFloorFeatures()
}

function getGarageFeatures(features, scope) {
  if (!requiredScopeForGarage(scope)) return null
  return features.garage || defaultGarageFeatures()
}

function getSidingFeatures(features, scope) {
  if (!requiredScopeForSiding(scope)) return null
  return features.siding || defaultSidingFeatures()
}

function getPerformanceFeatures(features, scope) {
  if (!requiredScopeForPerformance(scope)) return null
  return features.performance || defaultPerformanceFeatures()
}

const allScopeOfWork = {
  foundations: true,
  frameAndRoof: true,
  insulationEnvelope: true,
  windows: true
}

const requiredScopeForFoundation = scope => scope.foundations
const requiredScopeForStructuralConcrete = scope => scope.foundations
const requiredScopeForFramework = scope => scope.frameAndRoof
const requiredScopeForRoof = scope => scope.frameAndRoof
const requiredScopeForFloor = scope => scope.frameAndRoof || scope.insulationEnvelope
const requiredScopeForGarage = scope => scope.foundations || scope.frameAndRoof
const requiredScopeForSiding = scope => scope.insulationEnvelope
const requiredScopeForPerformance = scope => scope.foundations && scope.frameAndRoof && scope.insulationEnvelope && scope.windows
