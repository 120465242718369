<template>
  <div>
    <fieldset class="form-group">
      <legend class="col-form-label-sm" data-test-id="dwelling-unit-address-line-label">{{ label(index) }}</legend>
      <div class="row">
        <div class="col-xs-4 col-xl-2 left-address-field">
          <input-gcr data-test-id="dwelling-unit-address-line-building-number"
                     :placeholder="$t('dwellingUnitAddressBuildingNumber')"
                     v-model="dwellingUnit.buildingNumber"
                     :disabled="buildingNumberReadonly"
          />
        </div>
        <div class="offset-xs-5 col-xs-3 offset-xl-0 col-xl-2 middle-address-field">
          <input-gcr data-test-id="dwelling-unit-address-line-apartment"
                     :placeholder="$t('dwellingUnitAddressApartment')"
                     v-model="dwellingUnit.apartment" />
        </div>
        <div class="col-xs-8 col-xl-5 middle-address-field">
          <input-gcr data-test-id="dwelling-unit-address-line-street"
                     :placeholder="$t('dwellingUnitAddressStreet')"
                     v-model="dwellingUnit.street"
                     :disabled="streetReadonly"
          />
        </div>
        <div class="col-xs-4 col-xl-3 right-address-field">
          <input-gcr data-test-id="dwelling-unit-address-line-postal-code"
                     :placeholder="$t('dwellingUnitAddressPostalCode')"
                     inputmask="A#A #A#"
                     v-model="dwellingUnit.postalCode"
                     :validation-feedback="postalCodeValidationFeedback"
                     :disabled="postalCodeReadonly"
          />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
import InputGcr from '@/components/atoms/Input'
import Validators from '@/_helpers/validators'

export default {
  components: { InputGcr },
  props: {
    index: Number,
    dwellingUnit: Object,
    buildingNumberReadonly: Boolean,
    streetReadonly: Boolean,
    postalCodeReadonly: Boolean
  },
  computed: {
    postalCodeValidationFeedback() {
      if (!this.dwellingUnit.postalCode) return ''
      return Validators.postalCode(this.dwellingUnit.postalCode, this.$t('dwellingUnitAddressPostalCodeFormat'))
    }
  },
  methods: {
    label(index) {
      return index === 1 ? this.$t('dwellingUnitMainAddressLineLabel') : this.$t('dwellingUnitAddressLineLabel', { index })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../utilities/config';

fieldset {
  border-top: 2px solid theme-color('gray');
  margin: 0;
  padding: 5px
}

legend {
  margin-left: 4px;
  margin-bottom: 0;
  width: auto;
  padding: 0 5px 0 5px;
}

.address-field {
  @media screen and (max-width: breakpoint-max('lg')) {
    margin-bottom: 5px;
  }
}

.middle-address-field {
  @extend .address-field;
  @media screen and (min-width: breakpoint-min('xl')) {
    padding-right: 3px;
    padding-left: 3px;
  }
}

.left-address-field {
  @extend .address-field;
  @media screen and (min-width: breakpoint-min('xl')) {
    padding-right: 3px;
  }
}

.right-address-field {
  @extend .address-field;
  @media screen and (min-width: breakpoint-min('xl')) {
    padding-left: 3px;
  }
}
</style>