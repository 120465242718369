<template>
  <div class="row">
    <div class="form__col col">
      <radio-group
        data-test-id="land-owner-type"
        group="land-owner-type"
        :options="landOwnerTypeOptions"
        :label="$t('landOwner')"
        v-model="landOwner.type"
        :validation-feedback="landOwnerTypeValidationFeedback"
      />
      <template v-if="typeIsThirdParty">
        <input-gcr
          class="col-sm-10"
          data-test-id="land-owner-third-party-name"
          :placeholder="$t('siteOwnerName')"
          v-model="landOwner.thirdPartyName"
          :validation-feedback="landOwnerThirdPartyNameValidationFeedback"
        />
        <div class="col input-desc" data-test-id="third-party-note" v-html="this.thirdPartyNote" />
      </template>
    </div>
  </div>
</template>

<script>
import RadioGroup from '@/components/atoms/RadioGroup'
import InputGcr from '@/components/atoms/Input'
import Validators from '@/_helpers/validators'

export default {
  components: { RadioGroup, InputGcr },
  props: {
    landOwner: Object,
    disableBeneficiaryOption: Boolean,
    contractorName: String,
    thirdPartyNote: String
  },
  computed: {
    landOwnerTypeValidationFeedback() {
      return Validators.required(this.landOwner.type, this.$t('landOwnerRequired'))
    },
    landOwnerThirdPartyNameValidationFeedback() {
      return Validators.required(this.landOwner.thirdPartyName, this.$t('siteOwnerNameRequired'))
    },
    typeIsThirdParty() {
      return this.landOwner.type === 'THIRD_PARTY'
    },
    landOwnerTypeOptions() {
      return [
        { label: this.$t('landOwnerContractor', { contractorName: this.contractorName }), value: 'CONTRACTOR' },
        { label: this.$t('landOwnerBeneficiary'), value: 'BENEFICIARY', disabled: this.disableBeneficiaryOption },
        { label: this.$t('landOwnerThirdParty'), value: 'THIRD_PARTY' }
      ]
    }
  },
  watch: {
    'landOwner.type'(newValue) {
      if (newValue !== 'THIRD_PARTY')
        this.landOwner.thirdPartyName = null
    },
    disableBeneficiaryOption: {
      immediate: true,
      handler(isBeneficiaryDisabled) {
        if (isBeneficiaryDisabled && this.landOwner.type === 'BENEFICIARY')
          this.landOwner.type = null
      }
    }
  }
}
</script>
