import { defaultTechnicalFeatures } from '@/_models/technical_features'
import { anEmptyDwellingUnit } from '@/_helpers/dwelling_units'

export default {
  newDraft() {
    return {
      type: null,
      implantationType: null,
      aboveGroundStoreyCount: null,
      totalStoreyCount: null,
      lotNumber: null,
      dwellingUnits: [anEmptyDwellingUnit()],
      dwellingUnitCount: 1,
      synchronizeAddresses: true,
      isRental: false,
      sale: {
        price: null,
        hasKnownBeneficiary: null,
        contractNumber: null,
        plannedDeliveryDate: null,
        scopeOfWork: null
      },
      landOwner: {
        type: null,
        thirdPartyName: null
      },
      plannedDates: emptyPlannedDates(),
      contacts: [],
      technicalFeatures: defaultTechnicalFeatures()
    }
  },
  newFor(project) {
    return {
      ...this.newDraft(),
      ...fromProject(project)
    }
  },
  fromGraphql(building) {
    return {
      id: building.id,
      type: building.type,
      implantationType: building.implantationType,
      aboveGroundStoreyCount: building.aboveGroundStoreyCount,
      totalStoreyCount: building.totalStoreyCount,
      lotNumber: building.lotNumber,
      isRental: building.isRental,
      sale: mapSale(building.sale),
      dwellingUnits: building.dwellingUnits.map(mapDwellingUnit),
      dwellingUnitCount: building.dwellingUnits.length,
      synchronizeAddresses: building.synchronizeAddresses,
      landOwner: {
        type: building.landOwner.type,
        thirdPartyName: building.landOwner.thirdPartyName
      },
      plannedDates: {
        start: building.plannedDates.start,
        foundationBackfilling: building.plannedDates.foundationBackfilling,
        wallCoverings: building.plannedDates.wallCoverings,
        insideWallCoverings: building.plannedDates.insideWallCoverings,
        end: building.plannedDates.end
      },
      contacts: building.contacts.map(mapContact),
      technicalFeatures: mapTechnicalFeatures(building.technicalFeatures)
    }
  },
  copyOf(building) {
    return {
      ...building,
      dwellingUnits: building.dwellingUnits.map(copyDwellingUnit),
      sale: building.sale && copySale(building.sale),
      id: null,
      lotNumber: null,
      plannedDates: emptyPlannedDates()
    }
  },
  toGraphql(building) {
    return {
      ...filterGraphqlProperties(building),
      technicalFeatures: toGraphqlTechnicalFeatures(building.technicalFeatures)
    }
  }
}

function emptyPlannedDates() {
  return {
    start: null,
    foundationBackfilling: null,
    wallCoverings: null,
    insideWallCoverings: null,
    end: null
  }
}

function copyDwellingUnit(du) {
  return {
    ...du,
    lotNumber: null,
    buildingNumber: null
  }
}

function copySale(sale) {
  return {
    ...sale,
    contractNumber: null,
    plannedDeliveryDate: null
  }
}

function fromProject(project) {
  if (project.type !== 'SOLE_OWNERSHIP') return

  return {
    lotNumber: project.lotNumber,
    dwellingUnits: [{
      ...anEmptyDwellingUnit(),
      street: project.streetName,
      buildingNumber: project.streetNumber,
      postalCode: project.postalCode
    }],
    ...fromDraft(project.drafts.registrationForm?.json)
  }
}

function fromDraft(draftJson) {
  if (!draftJson) return
  const draft = JSON.parse(draftJson)

  return {
    type: draft.buildingType,
    implantationType: draft.implantationType,
    aboveGroundStoreyCount: getAboveGroundStoreyCount(draft.floorCount),
    plannedDates: {
      start: valueOrNull(draft.plannedStart),
      foundationBackfilling: valueOrNull(draft.plannedFoundationBackfilling),
      wallCoverings: valueOrNull(draft.plannedWallCoverings),
      insideWallCoverings: valueOrNull(draft.plannedInsideWallCoverings),
      end: valueOrNull(draft.plannedEnd)
    },
    landOwner: getLandowner(draft),
    isRental: draft.buildingIntended === 'RENTAL',
    sale: getSale(draft),
    contacts: getContacts(draft.professionals),
    technicalFeatures: getTechnicalFeatures(draft)
  }
}

function valueOrNull(value) {
  return value || null
}

function getAboveGroundStoreyCount(floorCount) {
  switch (floorCount) {
    case 'ONE':
      return 1
    case 'TWO':
      return 2
    case 'THREE':
    case 'THREE_HALF':
      return 3
    case 'FOUR':
      return 4
  }
}

function getLandowner(draft) {
  if (draft.landOwnerIsCompany === true)
    return {
      type: 'CONTRACTOR',
      thirdPartyName: null
    }
  if (draft.landOwnerIsCompany === false)
    return {
      type: 'THIRD_PARTY',
      thirdPartyName: null
    }
  return {
    type: null,
    thirdPartyName: null
  }
}

function getSale(draft) {
  if (draft.buildingIntended === 'RENTAL') return null
  return {
    price: draft.salePrice,
    hasKnownBeneficiary: draft.beneficiaryKnown,
    contractNumber: !draft.beneficiaryKnown ? null : draft.contractNumber,
    scopeOfWork: !draft.beneficiaryKnown ? null : getScopeOfWork(draft),
    plannedDeliveryDate: null
  }
}

function getScopeOfWork(draft) {
  return draft.landOwnerIsCompany === false ? {
    foundations: draft.workdoneFoundation,
    frameAndRoof: draft.workdoneRoof,
    insulationEnvelope: draft.workdoneEvelope,
    windows: draft.workdoneWindows
  } : null
}

function getContacts(professionals) {
  return professionals.map(p => ({
    roles: ['PLANNER', 'REPRESENTATIVE'],
    firstName: p.firstName,
    lastName: p.lastName,
    phoneNumber: p.cellPhone,
    email: p.email
  }))
}

function getTechnicalFeatures(draft) {
  let features = defaultTechnicalFeatures()
  features.framework.mainFramework = getMainFramework(draft)
  features.roof.greenRoof = draft.unconventionalGreenRoof
  features.roof.roofDefaults = !draft.unconventionalGreenRoof
  features.siding.acrylicCoating = draft.unconventionalAcrylic
  features.other.otherTechnicalFeatures = getOtherTechnicalFeatures(draft)
  features.other.indoorOrTerracePool = getIndoorOrTerracePool(draft.pool)
  features.existingFoundation = getExistingFoundation(draft.existingFoundation)
  features.hasOtherTechnicalFeatures = features.other.otherTechnicalFeatures !== null || features.other.indoorOrTerracePool || null
  return features
}

function getMainFramework(draft) {
  if (draft.unconventionalInsulating) return 'icfHome'
  if (draft.unconventionalRoundWood) return 'logHouse'
  if (draft.structureConcrete) return 'concreteFrame'
  if (draft.structureSteel) return 'steelFrame'
  return 'woodFrame'
}

function getOtherTechnicalFeatures(draft) {
  if (!draft.unconventionalOther) return null
  return draft.unconventionalOtherText
}

function getIndoorOrTerracePool(pool) {
  return pool && ['INTERIOR', 'EXTERIOR'].includes(pool)
}

function getExistingFoundation(existingFoundation) {
  switch (existingFoundation) {
    case 'RECONSTRUCTION':
      return 'reconstructionAfterDisaster'
    case 'RENOVATION' :
      return 'renovation'
    case 'UNNECESSARY' :
      return 'other'
    case 'NONE' :
      return 'none'
    default:
      return null
  }
}

function mapSale(sale) {
  return sale && {
    price: sale.price,
    hasKnownBeneficiary: sale.hasKnownBeneficiary,
    contractNumber: sale.contractNumber,
    plannedDeliveryDate: sale.plannedDeliveryDate,
    scopeOfWork: mapScopeOfWork(sale.scopeOfWork)
  }
}

function mapScopeOfWork(scopeOfWork) {
  return scopeOfWork && {
    foundations: scopeOfWork.foundations,
    frameAndRoof: scopeOfWork.frameAndRoof,
    insulationEnvelope: scopeOfWork.insulationEnvelope,
    windows: scopeOfWork.windows
  }
}

function mapDwellingUnit(dwellingUnit) {
  return {
    lotNumber: dwellingUnit.lotNumber,
    buildingNumber: dwellingUnit.buildingNumber,
    apartment: dwellingUnit.apartment,
    street: dwellingUnit.street,
    postalCode: dwellingUnit.postalCode,
    sale: mapSale(dwellingUnit.sale)
  }
}

function mapContact(contact) {
  return {
    roles: contact.roles,
    firstName: contact.firstName,
    lastName: contact.lastName,
    phoneNumber: contact.phoneNumber,
    email: contact.email,
    company: contact.company
  }
}

function mapTechnicalFeatures(technicalFeatures) {
  let featureMap = new Map(technicalFeatures.map(f => [f.feature, mapTechnicalFeatureType(f)]))

  return {
    existingFoundation: featureMap.get('existingFoundation'),
    foundation: {
      foundationDefaults: featureMap.get('foundationDefaults'),
      concreteFoundation: featureMap.get('concreteFoundation'),
      groundSlab: featureMap.get('groundSlab'),
      foundationIcf: featureMap.get('foundationIcf'),
      foundationPiles: featureMap.get('foundationPiles'),
      foundationOther: featureMap.get('foundationOther')
    },
    framework: {
      mainFramework: featureMap.get('mainFramework')
    },
    floor: {
      floorJoist: featureMap.get('floorJoist')
    },
    roof: {
      roofDefaults: featureMap.get('roofDefaults'),
      pitchedRoof: featureMap.get('pitchedRoof'),
      flatRoof: featureMap.get('flatRoof'),
      terraceRoof: featureMap.get('terraceRoof'),
      greenRoof: featureMap.get('greenRoof')
    },
    siding: {
      masonry: featureMap.get('masonry'),
      lightCoating: featureMap.get('lightCoating'),
      acrylicCoating: featureMap.get('acrylicCoating'),
      otherSiding: featureMap.get('otherSiding')
    },
    garage: {
      garage: featureMap.get('garage'),
      garageCommonAccessPath: featureMap.get('garageCommonAccessPath'),
      structuralConcreteGarageSlab: featureMap.get('structuralConcreteGarageSlab'),
      sharedUsageGarage: featureMap.get('sharedUsageGarage')
    },
    hasOtherTechnicalFeatures: featureMap.get('hasOtherTechnicalFeatures'),
    structuralConcrete: {
      livingSpaceUnderConcreteBalcony: featureMap.get('livingSpaceUnderConcreteBalcony'),
      retainingWall: featureMap.get('retainingWall'),
      otherStructuralConcreteStructure: featureMap.get('otherStructuralConcreteStructure')
    },
    performance: {
      novoclimat: featureMap.get('novoclimat'),
      leed: featureMap.get('leed'),
      netZero: featureMap.get('netZero'),
      passiveHouse: featureMap.get('passiveHouse')
    },
    other: {
      indoorOrTerracePool: featureMap.get('indoorOrTerracePool'),
      fireSprinkler: featureMap.get('fireSprinkler'),
      elevator: featureMap.get('elevator'),
      sharedVentilation: featureMap.get('sharedVentilation'),
      sharedCivilInfrastructure: featureMap.get('sharedCivilInfrastructure'),
      tinyHome: featureMap.get('tinyHome'),
      mixedUseBuilding: featureMap.get('mixedUseBuilding'),
      otherTechnicalFeatures: featureMap.get('otherTechnicalFeatures')
    }
  }
}

function mapTechnicalFeatureType(technicalFeature) {
  if (technicalFeature.value === 'true') return true
  if (technicalFeature.value === 'false') return false
  return technicalFeature.value
}

function toGraphqlTechnicalFeatures(features) {
  return Object
    .entries(features)
    .flatMap(toGraphqlTechnicalFeature)
    .filter(isNotNullOrUndefined)
}

function isNotNullOrUndefined(feature) {
  return feature.value !== null && feature.value !== undefined
}

function filterGraphqlProperties(building) {
  const { dwellingUnitCount, ...propertiesToKeep } = building
  return propertiesToKeep
}

function toGraphqlTechnicalFeature([feature, value]) {
  if (value && typeof value === 'object')
    return Object.entries(value).map(toGraphqlTechnicalFeature)
  return { feature, value: value?.toString() }
}
