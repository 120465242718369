<template>
  <div>
    <div class="row">
      <strong class="section-title form__col col" data-test-id="garage-title">{{ $t('garage') }}</strong>
    </div>
    <div class="row">
      <radio-group
        class="col"
        data-test-id="garage"
        group="garage"
        :options="garageOptions"
        v-model="technicalFeatures.garage"
        :validation-feedback="garageValidationFeedback"
      />
    </div>
    <template v-if="hasGarage">
      <div class="row">
        <label data-test-id="garage-label" class="form__col col">{{ $t('garageLabel') }}</label>
      </div>
      <div class="row">
        <div class="col">
          <checkbox-gcr
            data-test-id="structural-concrete-garage-slab"
            :label="$t('structuralConcreteGarageSlab')"
            v-model="technicalFeatures.structuralConcreteGarageSlab"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <checkbox-gcr
            data-test-id="shared-usage-garage"
            :tooltip="$t('sharedUsageGarageInfo')"
            :label="$t('sharedUsageGarage')"
            v-model="technicalFeatures.sharedUsageGarage"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <checkbox-gcr
            data-test-id="garage-common-access-path"
            :tooltip="$t('garageCommonAccessPathInfo')"
            :label="$t('garageCommonAccessPath')"
            v-model="technicalFeatures.garageCommonAccessPath"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import RadioGroup from '@/components/atoms/RadioGroup'
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import Validators from '@/_helpers/validators'

export default {
  components: { RadioGroup, CheckboxGcr },
  props: {
    technicalFeatures: Object
  },
  data() {
    return {
      garageOptions: [
        { label: this.$t('withoutGarage'), value: false },
        { label: this.$t('withGarage'), value: true }
      ]
    }
  },
  computed: {
    garageValidationFeedback() {
      return Validators.required(this.technicalFeatures.garage, this.$t('garageRequired'))
    },
    hasGarage() {
      return this.technicalFeatures.garage
    }
  },
  watch: {
    hasGarage(hasGarage) {
      if (hasGarage) return
      this.technicalFeatures.garageCommonAccessPath = null
      this.technicalFeatures.structuralConcreteGarageSlab = null
      this.technicalFeatures.sharedUsageGarage = null
    }
  }
}
</script>
