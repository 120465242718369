<template>
  <form class="form">
    <form-header :title="title" :cancel-url="cancelUrl">
      <slot name="buttons"></slot>
    </form-header>
    <slot></slot>
    <form-footer :cancel-url="cancelUrl">
      <slot name="buttons"></slot>
    </form-footer>
  </form>
</template>

<script>
import FormHeader from '@/components/molecules/FormHeader'
import FormFooter from '@/components/molecules/FormFooter'

export default {
  components: {
    FormHeader,
    FormFooter
  },
  props: {
    title: String,
    cancelUrl: Object
  }
}
</script>
