<template>
  <div>
    <div class="row">
      <strong class="section-title form__col col" data-test-id="floor-title">{{ $t('floor') }}</strong>
    </div>
    <div class="row">
      <radio-group
        class="col"
        data-test-id="floor-joist"
        group="floor-joist"
        :options="floorJoistOptions"
        :label="$t('floorJoist')"
        v-model="technicalFeatures.floorJoist" />
    </div>
  </div>
</template>

<script>
import RadioGroup from '@/components/atoms/RadioGroup'

export default {
  components: { RadioGroup },
  props: {
    technicalFeatures: Object
  },
  data() {
    return {
      floorJoistOptions: [
        { label: this.$t('spacing400mmOrLess'), value: 'spacing400mmOrLess' },
        { label: this.$t('spacing500mmOrMore'), value: 'spacing500mmOrMore' }
      ]
    }
  }
}
</script>
