<template>
  <div>
    <div class="row">
      <strong class="section-title form__col col" data-test-id="siding-title">{{ $t('siding') }}</strong>
    </div>
    <div class="row">
      <div class="col">
        <checkbox-gcr
          data-test-id="acrylic-coating"
          :label="$t('acrylicCoating')"
          :tooltip="$t('acrylicCoatingInfo')"
          v-model="technicalFeatures.acrylicCoating"
          :show-validation-feedback=false
          :validation-feedback="sidingValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <checkbox-gcr
          data-test-id="masonry"
          :label="$t('masonry')"
          v-model="technicalFeatures.masonry"
          :show-validation-feedback=false
          :validation-feedback="sidingValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <checkbox-gcr
          data-test-id="light-coating"
          :label="$t('lightCoating')"
          :tooltip="$t('lightCoatingInfo')"
          v-model="technicalFeatures.lightCoating"
          :show-validation-feedback=false
          :validation-feedback="sidingValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <text-option
          data-test-id="other-siding"
          :label="$t('otherSiding')"
          :placeholder="$t('otherSidingPlaceholder')"
          v-model="technicalFeatures.otherSiding"
          :validation-feedback="sidingValidationFeedback"
        />
      </div>
    </div>
    <div class="row col invalid-feedback" data-test-id="siding-validation-feedback">{{ sidingValidationFeedback }}</div>
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import Validators from '@/_helpers/validators'
import TextOption from '@/components/atoms/TextOption'

export default {
  components: { CheckboxGcr, TextOption },
  props: {
    technicalFeatures: Object
  },
  computed: {
    sidingValidationFeedback() {
      return Validators.test(this.anySelected(), this.$t('sidingChoiceRequired'))
    }
  },
  methods: {
    anySelected() {
      return this.technicalFeatures.acrylicCoating ||
        this.technicalFeatures.masonry ||
        this.technicalFeatures.lightCoating ||
        this.technicalFeatures.otherSiding != null
    }
  }
}
</script>


