<template>
  <div>
    <div class="form__step row">
      <div class="col-xs-10 col-sm-8 col-lg-6 offset-xs-1 offset-md-2 form__step__list">
        <step-number
          v-for="step in stepCount"
          :number="step"
          :active="step === activeStep"
          :separator="step !== stepCount"
          :key="step"
        />
      </div>
    </div>
    <div data-test-id="form-step" class="form__body row" :class="stepValidationClass">
      <div class="col-xs-10 col-sm-8 col-lg-6 offset-xs-1 offset-md-2">
        <form-step :node="activeStepContent()" />
      </div>
    </div>
  </div>
</template>

<script>
import StepNumber from '@/components/atoms/StepNumber'
import FormStep from '@/components/molecules/VNode'

export default {
  components: { StepNumber, FormStep },
  props: {
    activeStep: Number,
    lastValidatedStep: Number
  },
  methods: {
    activeStepContent() {
      return this.$slots.default[this.activeStep - 1]
    }
  },
  computed: {
    stepCount() {
      return this.$slots.default.length
    },
    stepValidationClass() {
      return { 'was-validated': this.lastValidatedStep >= this.activeStep }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../utilities/config';

.form__step {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: gray('white');
  border-bottom: 1px solid gray('gray-150');
  &__list {
    display: flex;
    position: relative;

    > :not(:last-child) {
      flex-grow: 1;
    }
  }
}
</style>
