<template>
  <div v-if="showWarning" class="row">
    <span class="form__col col input-desc" data-test-id="warning" v-html="$t('unmanagedBeneficiaryWarning')" />
  </div>
</template>

<script>
import getProjectBuyers from '@/graphql/accreditations/projects/getProjectBuyers.graphql'
import { mapGetters } from 'vuex'
import { Consts } from '@/_helpers'

export default {
  data() {
    return {
      projectHasAtLeastOneBuyer: false
    }
  },
  props: {
    isCoOwnership: Boolean,
    projectId: String
  },
  computed: {
    ...mapGetters({ accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID }),
    showWarning() {
      return this.isCoOwnership && this.projectHasAtLeastOneBuyer
    }
  },
  apollo: {
    getProjectBuyers: {
      query: getProjectBuyers,
      variables() {
        return {
          accredid: this.accreditationId,
          projectid: this.projectId
        }
      },
      update(data) {
        this.projectHasAtLeastOneBuyer = data.accreditation.project.buyers.length > 0
      }
    }
  }
}
</script>