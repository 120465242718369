<template>
  <div class="text-option">
    <checkbox-gcr
      :label="label"
      :checked="hasValue"
      :validation-feedback="validationFeedback"
      :show-validation-feedback=false
      @change="checkChange" />
    <div class="text-option-container" v-if="hasValue">
      <textarea
        class="form-control"
        v-bind="$attrs"
        :value="value"
        @input="textChanged" />
    </div>
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'

export default {
  components: { CheckboxGcr },
  inheritAttrs: false,
  props: {
    label: String,
    value: String,
    validationFeedback: {
      type: String,
      default: () => ''
    }
  },
  computed: {
    hasValue() {
      return this.value !== null && this.value !== undefined
    }
  },
  methods: {
    textChanged(event) {
      this.$emit('input', event.target.value)
    },
    checkChange(checked) {
      this.$emit('input', checked ? '' : null)
    }
  }
}
</script>

<style>
.text-option-container {
  margin-left: 15px;
}
</style>
