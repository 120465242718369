<template>
  <div>
    <div class="row">
      <strong class="section-title form__col col" data-test-id="performance-title">{{ $t('performance') }}</strong>
    </div>
    <div class="row">
      <div class="col-12 col-xs-auto">
        <checkbox-gcr
          data-test-id="novoclimat"
          :label="$t('novoclimat')"
          v-model="technicalFeatures.novoclimat"
        />
        <checkbox-gcr
          data-test-id="leed"
          :label="$t('leed')"
          v-model="technicalFeatures.leed" />
      </div>
      <div class="col">
        <checkbox-gcr
          data-test-id="net-zero"
          :label="$t('netZero')"
          v-model="technicalFeatures.netZero"
        />
        <checkbox-gcr
          data-test-id="passive-house"
          :label="$t('passiveHouse')"
          v-model="technicalFeatures.passiveHouse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'

export default {
  components: { CheckboxGcr },
  props: {
    technicalFeatures: Object
  },
  data() {
    return {
      yesNoOptions: [
        { label: this.$t('yes'), value: true },
        { label: this.$t('no'), value: false }
      ]
    }
  }
}
</script>
