export function address({ apartment, buildingNumber, street, city }) {
  if (street) {
    return `${city}, ${shortAddress(arguments[0])}`
  }
  return `${city}`
}

export function shortAddress({ apartment, buildingNumber, street }) {
  if (!buildingNumber) return street
  if (!apartment) return `${buildingNumber} ${street}`
  return `${apartment}-${buildingNumber} ${street}`
}