<template>
  <div class="form__container">
    <land-owner-selection
      data-test-id="land-owner-type"
      class="land-owner"
      :land-owner="building.landOwner"
      disable-beneficiary-option
      :contractor-name="contractorName"
      :third-party-note=null
    />
    <h3 data-test-id="co-ownership-sales-heading" class="form__subtitle">{{ $t('coOwnershipSales') }}</h3>
    <condo-sale-line
      v-for="(dwellingUnit, index) in building.dwellingUnits"
      :key="index"
      :index="index + 1"
      :dwelling-unit="dwellingUnit"
    />
  </div>
</template>

<script>
import CondoSaleLine from '@/components/building/edit/sales/CondoSaleLine'
import LandOwnerSelection from '@/components/building/edit/sales/LandOwnerSelection'

export default {
  components: { CondoSaleLine, LandOwnerSelection },
  props: {
    building: Object,
    contractorName: String
  }
}
</script>

<style lang="scss">
@import './src/utilities/config';

.land-owner {
  .form__col {
    margin: 0;
  }
  .radio-group-label {
    display: flex;
    padding-bottom: 8px;
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 500;
    color: gray('gray-700');
    border-bottom: 4px solid gray('gray-150');
  }
}
</style>
