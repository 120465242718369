import i18n from '@/i18n'
import { anEmptyDwellingUnit, convertToApartment, convertToCondo } from '@/_helpers/dwelling_units'

const buildingTypeDescriptions = {
  SINGLE_FAMILY: i18n.t('projectDescSingleFamily'),
  DUPLEX: i18n.t('projectDescDuplex'),
  TRIPLEX: i18n.t('projectDescTriplex'),
  QUADRUPLEX: i18n.t('projectDescQuadruplex'),
  QUINTUPLEX: i18n.t('projectDescQuintuplex'),
  INTERGENERATIONAL: i18n.t('projectDescIntergenerational'),
  MULTI_FAMILY: i18n.t('projectDescMultiFamily'),
  MULTICONDOS: i18n.t('projectDescMulticondos')
}

const implantationTypeDescriptions = {
  ROW: i18n.t('projectDescRow'),
  SEMI_DETACHED: i18n.t('projectDescSemiDetached'),
  DETACHED: '',
  null: ''
}

export function buildingDescription(building) {
  const buildingType = buildingTypeDescriptions[building.type]
  const implantationType = implantationTypeDescriptions[building.implantationType]
  if (buildingType !== undefined && implantationType !== undefined)
    return `${buildingType} ${implantationType}`.trim()
  return null
}

export function buildingDetailsChanged(building) {
  resizeDwellingUnits(building.dwellingUnits, building.dwellingUnitCount)
  synchronizeOwnership(building)
  synchronizeAddresses(building)
}

export function resizeDwellingUnits(dwellingUnits, count) {
  resize(dwellingUnits, count, anEmptyDwellingUnit)
}

export function synchronizeOwnership(building) {
  if (isCoOwnership(building)) toCoOwnership(building)
  else toSoleOwnership(building)
}

function toCoOwnership(building) {
  building.isRental = false
  building.sale = null
  building.dwellingUnits.forEach(convertToCondo)
}

function toSoleOwnership(building) {
  if (building.isRental)
    building.sale = null
  else
    building.sale ||= {
      price: null,
      hasKnownBeneficiary: null,
      contractNumber: null,
      plannedDeliveryDate: null,
      scopeOfWork: null
    }
  building.dwellingUnits.forEach(convertToApartment)
}

function isCoOwnership(building) {
  return building.type === 'MULTICONDOS'
}

function addressSyncProps(building) {
  const { buildingNumber, street, postalCode } = building.dwellingUnits[0]
  return isCoOwnership(building) ? { buildingNumber, street, postalCode } : { street, postalCode }
}

export function synchronizeAddresses(building) {
  if (!building.synchronizeAddresses) return
  assignEach(building.dwellingUnits, addressSyncProps(building))
}

function resize(list, newCount, newItem) {
  while (newCount > list.length)
    list.push(newItem())

  while (newCount < list.length)
    list.pop()
}

function assignEach(items, props) {
  items.forEach(i => Object.assign(i, props))
}
