<template>
  <div :class="{ 'was-validated': wasValidated }">
    <div class="row">
      <div class="col-12 col-md-6 form__col">
        <select-gcr
          data-test-id="contact-role"
          label="contactRole"
          :options="contactRoles"
          v-model="contactInEdition.role"
          :validation-feedback="roleValidationFeedback"
        />
      </div>
      <div v-if="hasAdditionalContractorRoles" class="col-12 col-md-6 form__col">
        <label data-test-id="contractor-additional-roles-label">{{ $t('additionalContractorRoles') }}</label>
        <checkbox-gcr
          data-test-id="contractor-planner-role"
          :label="$t('contractorPlanner')"
          v-model="contactInEdition.contractorPlannerRole"
          :validation-feedback="additionalContractorRolesValidationFeedback"
        />
        <checkbox-gcr
          data-test-id="contractor-site-representative-role"
          :label="$t('contractorSiteRepresentative')"
          v-model="contactInEdition.contractorSiteRepresentativeRole"
          :validation-feedback="additionalContractorRolesValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 form__col">
        <input-gcr
          data-test-id="contact-first-name"
          label="contactFirstName"
          v-model.trim="contactInEdition.firstName"
          :validation-feedback="firstNameValidationFeedback"
        />
      </div>
      <div class="col-12 col-sm-6 form__col">
        <input-gcr
          data-test-id="contact-last-name"
          label="contactLastName"
          v-model.trim="contactInEdition.lastName"
          :validation-feedback="lastNameValidationFeedback"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6 form__col">
        <input-gcr
          data-test-id="contact-phone-number"
          label="contactPhoneNumber"
          v-model.trim="contactInEdition.phoneNumber"
        />
      </div>
      <div class="col-12 col-sm-6 form__col">
        <input-gcr
          data-test-id="contact-email"
          label="contactEmail"
          v-model.trim="contactInEdition.email"
        />
      </div>
    </div>
    <div class="row">
      <div class="col form__col">
        <input-gcr
          v-if="needsCompany"
          data-test-id="contact-company"
          label="contactCompany"
          v-model.trim="contactInEdition.company"
        />
      </div>
    </div>
    <div class="row">
      <div class="col form__col col-flex-justify-end">
        <button-gcr
          data-test-id="cancel-contact"
          label="cancel"
          type="button"
          btn-class="btn-outline-gray btn-sm"
          @btnclick="cancel"
        />
        <button-gcr
          data-test-id="save-contact"
          label="save"
          type="button"
          btn-class="btn-save-contact btn-primary btn-sm"
          @btnclick="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SelectGcr from '@/components/atoms/Select'
import ButtonGcr from '@/components/atoms/Button'
import InputGcr from '@/components/atoms/Input'
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import Validators from '@/_helpers/validators'

export default {
  components: { ButtonGcr, SelectGcr, InputGcr, CheckboxGcr },
  props: {
    contact: Object
  },
  data() {
    return {
      contactInEdition: {
        role: null,
        contractorPlannerRole: false,
        contractorSiteRepresentativeRole: false,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        company: null
      },
      contactRoles: [
        { text: 'contactRoleContractorRepresentative', value: 'CONTRACTOR_REPRESENTATIVE' },
        { text: 'contactRoleArchitecturalDesigner', value: 'ARCHITECTURAL_DESIGNER' },
        { text: 'contactRoleStructuralEngineer', value: 'STRUCTURAL_ENGINEER' },
        { text: 'contactRoleMechanicalEngineer', value: 'MECHANICAL_ENGINEER' },
        { text: 'contactRoleElectricalEngineer', value: 'ELECTRICAL_ENGINEER' },
        { text: 'contactRoleNotary', value: 'NOTARY' }
      ],
      wasValidated: false
    }
  },
  created() {
    this.contactInEdition = this.toView(this.contact)
  },
  computed: {
    needsCompany() {
      return [
        'ARCHITECTURAL_DESIGNER',
        'STRUCTURAL_ENGINEER',
        'MECHANICAL_ENGINEER',
        'ELECTRICAL_ENGINEER',
        'NOTARY'
      ].includes(this.contactInEdition.role)
    },
    hasAdditionalContractorRoles() {
      return this.contactInEdition.role === 'CONTRACTOR_REPRESENTATIVE'
    },
    roleValidationFeedback() {
      return Validators.required(this.contactInEdition.role, this.$t('contactRoleRequired'))
    },
    firstNameValidationFeedback() {
      return Validators.required(this.contactInEdition.firstName, this.$t('contactFirstNameRequired'))
    },
    lastNameValidationFeedback() {
      return Validators.required(this.contactInEdition.lastName, this.$t('contactLastNameRequired'))
    },
    additionalContractorRolesValidationFeedback() {
      if (this.hasAdditionalContractorRoles)
        return Validators.test(this.atLeastOneAdditionalRoleSelected(), this.$t('additionalContractorRolesRequired'))
      return ''
    }
  },
  methods: {
    atLeastOneAdditionalRoleSelected() {
      return this.contactInEdition.contractorPlannerRole || this.contactInEdition.contractorSiteRepresentativeRole
    },
    save() {
      this.wasValidated = true
      if (this.isValid()) {
        this.$emit('save', this.fromView(this.contactInEdition))
      }
    },
    toView(contact) {
      return {
        role: contact?.roles.includes('PLANNER') || contact?.roles.includes('REPRESENTATIVE') ? 'CONTRACTOR_REPRESENTATIVE' : contact?.roles[0],
        contractorPlannerRole: contact?.roles.includes('PLANNER'),
        contractorSiteRepresentativeRole: contact?.roles.includes('REPRESENTATIVE'),
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        phoneNumber: contact?.phoneNumber,
        email: contact?.email,
        company: contact?.company
      }
    },
    fromView(contact) {
      let roles = []
      if (contact.role !== 'CONTRACTOR_REPRESENTATIVE') roles.push(contact.role)
      if (contact.contractorPlannerRole) roles.push('PLANNER')
      if (contact.contractorSiteRepresentativeRole) roles.push('REPRESENTATIVE')

      return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        email: contact.email,
        phoneNumber: contact.phoneNumber,
        company: contact.company,
        roles: roles
      }
    },
    cancel() {
      this.$emit('cancel')
    },
    isValid() {
      return !this.roleValidationFeedback && !this.additionalContractorRolesValidationFeedback
        && !this.firstNameValidationFeedback && !this.lastNameValidationFeedback
    }
  },
  watch: {
    needsCompany(newValue) {
      if (!newValue)
        this.contactInEdition.company = null
    },
    hasAdditionalContractorRoles(newValue) {
      if (!newValue) {
        this.contactInEdition.contractorPlannerRole = false
        this.contactInEdition.contractorSiteRepresentativeRole = false
      }
    }
  }
}
</script>

<style scoped>
.col-flex-justify-end {
  display: flex;
  justify-content: flex-end;
}
</style>

<style>
.btn-save-contact {
  margin-left: 10px;
}
</style>

