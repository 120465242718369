<template>
  <div>
    <div class="row">
      <date-gcr class="form__col col-xs-auto"
                data-test-id="contract-planned-delivery-date"
                id="contract-planned-delivery-date"
                label="contractPlannedDeliveryDate"
                :optional="true"
                v-model="sale.plannedDeliveryDate"
                :validation-feedback="plannedDeliveryDateValidationFeedback"
      />
    </div>
    <div class="row">
      <input-gcr class="form__col col-xs-auto" data-test-id="contract-number"
                 :label="contractNumberLabel"
                 :optional="true"
                 v-model="sale.contractNumber"
                 :tooltip="$t('contractNumberNote')"
      />
    </div>
    <scope-of-work v-if="sale.scopeOfWork" :scope-of-work="sale.scopeOfWork" />
  </div>
</template>

<script>
import Validators from '@/_helpers/validators'
import InputGcr from '@/components/atoms/Input'
import DateGcr from '@/components/atoms/Date'
import ScopeOfWork from '@/components/building/edit/sales/ScopeOfWork'

export default {
  components: { DateGcr, InputGcr, ScopeOfWork },
  props: {
    sale: Object,
    landOwnerType: String
  },
  computed: {
    contractNumberLabel() {
      if (this.landOwnerType === 'CONTRACTOR') {
        return 'contractNumberTypePreliminary'
      } else if (this.isEnterpriseContract) {
        return 'contractNumberTypeEntreprise'
      }
      return 'contractNumberTypeUnknown'
    },
    isEnterpriseContract() {
      return this.landOwnerType === 'BENEFICIARY' || this.landOwnerType === 'THIRD_PARTY'
    },
    plannedDeliveryDateValidationFeedback() {
      return Validators.optionalDateIsAfterOrEqual(this.sale.plannedDeliveryDate, '2015-01-01', this.$t('invalidPlannedDate2015'))
    }
  },
  methods: {
    createDefaultScopeOfWork() {
      this.sale.scopeOfWork = this.sale.scopeOfWork || {
        foundations: false,
        frameAndRoof: false,
        insulationEnvelope: false,
        windows: false
      }
    }
  },
  watch: {
    isEnterpriseContract: {
      immediate: true,
      handler(enterpriseContract) {
        if (enterpriseContract)
          this.createDefaultScopeOfWork()
        else
          this.sale.scopeOfWork = null
      }
    }
  }
}
</script>
