<template>
  <div>
    <div class="row">
      <strong data-test-id="structural-concrete-title" class="section-title form__col col">{{ $t('structuralConcrete') }}</strong>
    </div>
    <div class="row">
      <div class="col">
        <checkbox-gcr
          data-test-id="living-space-under-concrete-balcony"
          :label="$t('livingSpaceUnderConcreteBalcony')"
          :tooltip="$t('livingSpaceUnderConcreteBalconyInfo')"
          v-model="technicalFeatures.livingSpaceUnderConcreteBalcony" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <checkbox-gcr
          data-test-id="retaining-wall"
          v-model="technicalFeatures.retainingWall"
          :tooltip="$t('retainingWallInfo')"
          :label="$t('retainingWall')" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <text-option
          data-test-id="other-structural-concrete-structure"
          :label="$t('otherStructuralConcreteStructure')"
          :placeholder="$t('otherStructuralConcreteStructurePlaceholder')"
          v-model="technicalFeatures.otherStructuralConcreteStructure" />
      </div>
    </div>
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import TextOption from '@/components/atoms/TextOption'

export default {
  components: { CheckboxGcr, TextOption },
  props: {
    technicalFeatures: Object
  }
}
</script>
