<template>
  <div>
    <co-ownership-sales v-if="isCoOwnership" :building="building" :contractor-name="contractorName" />
    <sole-ownership-sale v-else :building="building" :project-type="projectType" :contractor-name="contractorName" />
  </div>
</template>

<script>
import SoleOwnershipSale from '@/components/building/edit/sales/SoleOwnershipSale'
import CoOwnershipSales from '@/components/building/edit/sales/CoOwnershipSales'

export default {
  components: { SoleOwnershipSale, CoOwnershipSales },
  props: {
    building: Object,
    projectType: String,
    contractorName: String
  },
  computed: {
    isCoOwnership() {
      return this.building.type === 'MULTICONDOS'
    }
  }
}
</script>
