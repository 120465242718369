<template>
  <div>
    <div class="row">
      <div class="col form__col">
        <label>{{ $t('businessContractWorkDone') }}</label>
        <checkbox-gcr data-test-id="scope-of-work-foundations"
                      :label="$t('workdoneFoundation')"
                      v-model="scopeOfWork.foundations"
                      :show-validation-feedback=false
                      :validation-feedback="scopeOfWorkValidationFeedback" />
        <checkbox-gcr data-test-id="scope-of-work-frame-and-roof"
                      :label="$t('workdoneRoof')"
                      v-model="scopeOfWork.frameAndRoof"
                      :show-validation-feedback=false
                      :validation-feedback="scopeOfWorkValidationFeedback" />
        <checkbox-gcr data-test-id="scope-of-work-insulation-envelope"
                      v-model="scopeOfWork.insulationEnvelope"
                      :label="$t('workdoneEnvelope')"
                      :tooltip="$t('workdoneEnvelopeInfo')"
                      :show-validation-feedback=false
                      :validation-feedback="scopeOfWorkValidationFeedback" />
        <checkbox-gcr data-test-id="scope-of-work-windows"
                      :label="$t('workdoneWindows')"
                      v-model="scopeOfWork.windows"
                      :show-validation-feedback=false
                      :validation-feedback="scopeOfWorkValidationFeedback" />
        <checkbox-gcr data-test-id="scope-of-work-all"
                      :label="$t('workdoneAll')"
                      :checked="allChecked"
                      @change="toggleAll"
                      :show-validation-feedback=false
                      :validation-feedback="scopeOfWorkValidationFeedback" />
      </div>
    </div>
    <div class="row col invalid-feedback" data-test-id="scope-of-work-validation-feedback">{{ scopeOfWorkValidationFeedback }}</div>
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import Validators from '@/_helpers/validators'

export default {
  components: { CheckboxGcr },
  props: {
    scopeOfWork: Object
  },
  computed: {
    allChecked() {
      return this.scopeOfWork.foundations &&
        this.scopeOfWork.frameAndRoof &&
        this.scopeOfWork.insulationEnvelope &&
        this.scopeOfWork.windows
    },
    scopeOfWorkValidationFeedback() {
      return Validators.test(this.anySelected(), this.$t('scopeOfWorkRequired'))
    }
  },
  methods: {
    toggleAll(checked) {
      this.scopeOfWork.foundations = checked
      this.scopeOfWork.frameAndRoof = checked
      this.scopeOfWork.insulationEnvelope = checked
      this.scopeOfWork.windows = checked
    },
    anySelected() {
      return this.scopeOfWork.foundations ||
        this.scopeOfWork.frameAndRoof ||
        this.scopeOfWork.insulationEnvelope ||
        this.scopeOfWork.windows
    }
  }
}
</script>
