<template>
  <div>
    <h3 data-test-id="building-heading" class="form__subtitle">{{ $t('buildingIdentification') }}</h3>
    <div class="row">
      <div class="form__col col-sm">
        <select-gcr data-test-id="building-type"
                    label="buildingType"
                    :options="buildingTypeOptions"
                    v-model="building.type"
                    :validation-feedback="buildingTypeValidationFeedback"
        />
      </div>
      <div v-if="isCoOwnership" class="form__col col-sm-3">
        <input-gcr data-test-id="dwelling-unit-count"
                   label="dwellingUnitCount"
                   numeric
                   v-model="multicondoUnitCount"
                   @blur="updateDwellingUnitCount"
                   inputmask="##" />
      </div>
      <div class="form__col col-sm">
        <select-gcr data-test-id="implantation-type"
                    label="implantationType"
                    :options="options.implantationType"
                    v-model="building.implantationType"
                    :validation-feedback="implantationTypeValidationFeedback" />
      </div>
    </div>
    <unmanaged-beneficiary-warning data-test-id="unmanaged-beneficiary-warning" :is-co-ownership="isCoOwnership" :project-id="projectId" />
    <div class="row">
      <div class="form__col col-sm">
        <input-gcr data-test-id="above-ground-storey-count"
                   label="aboveGroundStoreyCount"
                   numeric
                   v-model="building.aboveGroundStoreyCount"
                   inputmask="#"
                   :tooltip="$t('aboveGroundStoreyCountInfo')"
                   :validation-feedback="aboveGroundStoreyCountFeedback" />
      </div>
      <div class="form__col col-sm">
        <input-gcr data-test-id="total-storey-count"
                   label="totalStoreyCount"
                   numeric
                   v-model="building.totalStoreyCount"
                   inputmask="#"
                   :tooltip="$t('totalStoreyCountInfo')"
                   :validation-feedback="totalStoreyCountFeedback" />
      </div>
    </div>
    <div class="row">
      <div class="form__col col-sm-6">
        <input-gcr data-test-id="lot-number"
                   v-model.trim="building.lotNumber"
                   :label="lotNumberLabel"
                   :validation-feedback="lotNumberValidationFeedback" />
      </div>
      <div class="form__col col" v-if="showCity">
        <input-gcr data-test-id="building-address-city"
                   label="dwellingUnitAddressCity"
                   v-model="projectCity"
                   disabled />
      </div>
    </div>
  </div>
</template>

<script>
import SelectGcr from '@/components/atoms/Select'
import InputGcr from '@/components/atoms/Input'
import Validators from '@/_helpers/validators'
import UnmanagedBeneficiaryWarning from '@/components/building/edit/details/UnmanagedBeneficiaryWarning'

const buildingTypeUnitCount = {
  null: 1,
  'SINGLE_FAMILY': 1,
  'DUPLEX': 2,
  'TRIPLEX': 3,
  'QUADRUPLEX': 4,
  'QUINTUPLEX': 5,
  'INTERGENERATIONAL': 2
}

export default {
  components: { SelectGcr, InputGcr, UnmanagedBeneficiaryWarning },
  props: {
    building: Object,
    projectCity: String,
    projectType: String,
    projectId: String
  },
  data() {
    return {
      options: {
        implantationType: [
          { text: 'detached', value: 'DETACHED' },
          { text: 'semiDetached', value: 'SEMI_DETACHED' },
          { text: 'row', value: 'ROW' }
        ]
      },
      multicondoUnitCount: null
    }
  },
  computed: {
    buildingTypeOptions() {
      let buildingTypes = []

      if (this.projectType === 'SOLE_OWNERSHIP' || this.projectType === 'HORIZONTAL_CO_OWNERSHIP')
        buildingTypes.push(
          { text: 'singleFamily', value: 'SINGLE_FAMILY' },
          { text: 'duplex', value: 'DUPLEX' },
          { text: 'triplex', value: 'TRIPLEX' },
          { text: 'quadruplex', value: 'QUADRUPLEX' },
          { text: 'quintuplex', value: 'QUINTUPLEX' },
          { text: 'intergenerational', value: 'INTERGENERATIONAL' }
        )
      if (this.projectType === 'VERTICAL_CO_OWNERSHIP')
        buildingTypes.push({ text: 'multicondos', value: 'MULTICONDOS' })
      return buildingTypes
    },
    lotNumberLabel() {
      return this.isCoOwnership ? 'commonLotNumber' : 'lotNumber'
    },
    buildingTypeValidationFeedback() {
      return Validators.required(this.building.type, this.$t('buildingTypeRequired'))
    },
    implantationTypeValidationFeedback() {
      return Validators.required(this.building.implantationType, this.$t('implantationTypeRequired'))
    },
    lotNumberValidationFeedback() {
      return Validators.required(this.building.lotNumber, this.$t('lotNumberRequired'))
    },
    aboveGroundStoreyCountFeedback() {
      return Validators.required(this.building.aboveGroundStoreyCount, this.$t('aboveGroundStoreyCountRequired')) ||
        Validators.test(this.validStoreyCounts(), this.$t('aboveGroundStoreyCountTooHigh'))
    },
    totalStoreyCountFeedback() {
      return Validators.required(this.building.totalStoreyCount, this.$t('totalStoreyCountRequired')) ||
        Validators.test(this.validStoreyCounts(), this.$t('totalStoreyCountTooLow'))
    },
    isCoOwnership() {
      return this.building.type === 'MULTICONDOS'
    },
    showCity() {
      return this.building.dwellingUnitCount > 1
    }
  },
  methods: {
    updateDwellingUnitCount() {
      if (this.multicondoUnitCount < 2) this.multicondoUnitCount = 2
      this.building.dwellingUnitCount = this.multicondoUnitCount
    },
    validStoreyCounts() {
      return !this.building.totalStoreyCount ||
        !this.building.aboveGroundStoreyCount ||
        this.building.totalStoreyCount >= this.building.aboveGroundStoreyCount
    }
  },
  watch: {
    'building.type': {
      immediate: true,
      handler(buildingType) {
        if (buildingType === 'MULTICONDOS') {
          this.multicondoUnitCount = this.building.dwellingUnitCount
          this.updateDwellingUnitCount()
        } else {
          this.building.dwellingUnitCount = buildingTypeUnitCount[buildingType]
        }
      }
    }
  }
}
</script>
