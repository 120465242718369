<template>
  <div>
    <div class="row">
      <strong data-test-id="foundation-title" class="section-title form__col col">{{ $t('foundation') }}</strong>
    </div>
    <template v-if="hasFoundationFeatures">
      <div class="row">
        <radio-group
          class="col"
          data-test-id="foundation-defaults"
          group="foundation-defaults"
          :options="conventionalFoundationOptions"
          v-model="technicalFeatures.foundation.foundationDefaults" />
      </div>
      <template v-if="!isDefault">
        <div class="row">
          <label data-test-id="foundation-unconventional-label" class="form__col col">{{ $t('foundationUnconventionalLabel') }}</label>
        </div>
        <div class="row">
          <div class="col">
            <checkbox-gcr
              data-test-id="foundation-concrete"
              :label="$t('concreteFoundation')"
              :show-validation-feedback=false
              :validation-feedback="foundationValidationFeedback"
              v-model="technicalFeatures.foundation.concreteFoundation" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <checkbox-gcr
              data-test-id="foundation-ground-slab"
              :show-validation-feedback=false
              :validation-feedback="foundationValidationFeedback"
              v-model="technicalFeatures.foundation.groundSlab"
              :tooltip="$t('groundSlabInfo')"
              :label="$t('groundSlab')" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <checkbox-gcr
              data-test-id="foundation-icf"
              :show-validation-feedback=false
              :validation-feedback="foundationValidationFeedback"
              v-model="technicalFeatures.foundation.foundationIcf"
              :label="$t('foundationIcf')" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <checkbox-gcr
              data-test-id="foundation-piles"
              :label="$t('foundationPiles')"
              :tooltip="$t('foundationPilesInfo')"
              :show-validation-feedback=false
              :validation-feedback="foundationValidationFeedback"
              v-model="technicalFeatures.foundation.foundationPiles" />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <text-option
              data-test-id="foundation-other"
              :label="$t('foundationOther')"
              :placeholder="$t('foundationOtherPlaceholder')"
              :validation-feedback="foundationValidationFeedback"
              v-model="technicalFeatures.foundation.foundationOther" />
          </div>
        </div>
        <div class="row col invalid-feedback" data-test-id="foundation-validation-feedback">{{ foundationValidationFeedback }}</div>
      </template>
    </template>
    <div class="row">
      <select-gcr
        class="form__col col-sm-8"
        data-test-id="existing-foundation"
        label="existingFoundation"
        :options="existingFoundationOptions"
        v-model="technicalFeatures.existingFoundation"
        :validation-feedback="existingFoundationValidationFeedback"
      />
    </div>
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import SelectGcr from '@/components/atoms/Select'
import Validators from '@/_helpers/validators'
import TextOption from '@/components/atoms/TextOption'
import RadioGroup from '@/components/atoms/RadioGroup'

export default {
  components: { CheckboxGcr, SelectGcr, TextOption, RadioGroup },
  props: {
    technicalFeatures: Object
  },
  data() {
    return {
      existingFoundationOptions: [
        { text: 'existingFoundationNone', value: 'none' },
        { text: 'existingFoundationReconstruction', value: 'reconstructionAfterDisaster' },
        { text: 'existingFoundationRenovation', value: 'renovation' },
        { text: 'existingFoundationOther', value: 'other' }
      ],
      conventionalFoundationOptions: [
        { label: this.$t('concreteFoundationOnly'), value: true },
        { label: this.$t('otherFoundationTypes'), value: false }
      ]
    }
  },
  computed: {
    existingFoundationValidationFeedback() {
      return Validators.required(this.technicalFeatures.existingFoundation, this.$t('existingFoundationRequired'))
    },
    foundationValidationFeedback() {
      return Validators.test(this.anySelected, this.$t('foundationTypeRequired'))
    },
    anySelected() {
      return this.technicalFeatures.foundation.concreteFoundation ||
        this.technicalFeatures.foundation.groundSlab ||
        this.technicalFeatures.foundation.foundationIcf ||
        this.technicalFeatures.foundation.foundationPiles ||
        this.technicalFeatures.foundation.foundationOther != null
    },
    hasFoundationFeatures() {
      return this.technicalFeatures.foundation
    },
    isDefault() {
      return this.technicalFeatures.foundation?.foundationDefaults
    }
  },
  watch: {
    isDefault(newValue) {
      this.technicalFeatures.foundation.concreteFoundation = newValue || false
      this.technicalFeatures.foundation.groundSlab = false
      this.technicalFeatures.foundation.foundationIcf = false
      this.technicalFeatures.foundation.foundationPiles = false
      this.technicalFeatures.foundation.foundationOther = null
    }
  }
}
</script>

