<template>
  <table class="table table-striped">
    <tbody>
    <tr class="d-flex" v-for="(contact, index) in contacts">
      <th class="col" data-test-id="contact-full-name">
        {{ fullName(contact) }}
      </th>
      <td class="col-auto icons">
        <div class="phone-icon" data-test-id="phone-icon" :class="{'hidden': !contact.phoneNumber}"></div>
        <div class="email-icon" data-test-id="email-icon" :class="{'hidden': !contact.email}"></div>
      </td>
      <td class="col-auto icons">
        <button-gcr
            data-test-id="edit-button"
            btn-class="btn-edit btn-edit-sm"
            type="button"
            :group="false"
            v-tooltip="$t('edit')"
            @btnclick="edit(index)"
        />
        <confirmation-popover
            data-test-id="delete-button"
            btn-class="btn btn-delete btn-delete-sm"
            btn-tooltip-text="delete"
            confirm-title="contactDeleteConfirmTitle"
            accept-button-text="delete"
            cancel-button-text="cancel"
            @confirm="remove(index)"
        />
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import ButtonGcr from '@/components/atoms/Button'
import ConfirmationPopover from '@/components/molecules/ConfirmationPopover'

export default {
  components: { ButtonGcr, ConfirmationPopover },
  props: {
    contacts: Array
  },
  methods: {
    edit(index) {
      this.$emit('edit', index)
    },
    remove(index) {
      this.$emit('remove', index)
    },
    fullName(contact) {
      const parts = []
      if (contact.firstName) parts.push(contact.firstName)
      parts.push(contact.lastName)
      if (contact.company) parts.push(`(${contact.company})`)
      return parts.join(' ')
    }
  }
}
</script>

<style lang="scss">
@import '../../../../utilities/table/table';

.hidden {
  visibility: hidden;
}

.icons {
  display: flex;
}

.phone-icon {
  width: 18px;
  margin-right: 1px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../../../assets/telephone_gray.svg);
}

.email-icon {
  width: 18px;
  margin-left: 1px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../../../assets/email.svg);
}
</style>
