<template>
  <div>
    <div class="row">
      <strong class="section-title form__col col" data-test-id="roof-title">{{ $t('roof') }}</strong>
    </div>
    <div class="row">
      <radio-group
        class="col"
        data-test-id="roof-defaults"
        group="roof-defaults"
        :options="conventionalRoofOptions"
        v-model="technicalFeatures.roofDefaults"
      />
    </div>
    <template v-if="!isDefault">
      <div class="row">
        <label data-test-id="roof-unconventional-label" class="form__col col">{{ $t('roofUnconventionalLabel') }}</label>
      </div>
      <div class="row">
        <div class="col">
          <checkbox-gcr
            data-test-id="pitched-roof"
            :label="$t('pitchedRoof')"
            v-model="technicalFeatures.pitchedRoof"
            :show-validation-feedback=false
            :validation-feedback="roofValidationFeedback"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <checkbox-gcr
            data-test-id="flat-roof"
            v-model="technicalFeatures.flatRoof"
            :tooltip="$t('flatRoofInfo')"
            :label="$t('flatRoof')"
            :show-validation-feedback=false
            :validation-feedback="roofValidationFeedback"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <checkbox-gcr
            data-test-id="terrace-roof"
            v-model="technicalFeatures.terraceRoof"
            :label="$t('terraceRoof')"
            :show-validation-feedback=false
            :validation-feedback="roofValidationFeedback"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <checkbox-gcr
            data-test-id="green-roof"
            v-model="technicalFeatures.greenRoof"
            :label="$t('greenRoof')"
            :show-validation-feedback=false
            :validation-feedback="roofValidationFeedback"
          />
        </div>
      </div>
      <div class="row col invalid-feedback" data-test-id="roof-validation-feedback">{{ roofValidationFeedback }}</div>
    </template>
  </div>
</template>

<script>
import CheckboxGcr from '@/components/atoms/CheckboxNew'
import RadioGroup from '@/components/atoms/RadioGroup'
import Validators from '@/_helpers/validators'

export default {
  components: { CheckboxGcr, RadioGroup },
  props: {
    technicalFeatures: Object
  },
  data() {
    return {
      conventionalRoofOptions: [
        { label: this.$t('pitchedRoofOnly'), value: true },
        { label: this.$t('otherRoofType'), value: false }
      ]
    }
  },
  computed: {
    roofValidationFeedback() {
      return Validators.test(this.anySelected, this.$t('roofChoiceRequired'))
    },
    anySelected() {
      return this.technicalFeatures.pitchedRoof ||
        this.technicalFeatures.flatRoof ||
        this.technicalFeatures.terraceRoof ||
        this.technicalFeatures.greenRoof
    },
    isDefault() {
      return this.technicalFeatures.roofDefaults
    }
  },
  watch: {
    isDefault(newValue) {
      this.technicalFeatures.pitchedRoof = newValue || false
      this.technicalFeatures.flatRoof = false
      this.technicalFeatures.terraceRoof = false
      this.technicalFeatures.greenRoof = false
    }
  }
}
</script>

