<template>
  <form-with-steps
    :title="title"
    :submit-text="submitText"
    :cancel-url="projectUrl"
    :submitting="submitting"
    :readonly="isReadOnly"
    @submit="submit(saveAndSubmit)"
  >
    <template #actions>
      <button-gcr
        data-test-id="save-draft"
        type="button"
        btnClass="btn-outline-gray btn-sm form__header__btn"
        label="saveDraft"
        :loading="submitting"
        :group=false
        :disabled="isReadOnly"
        @btnclick="submit(saveBuildingDraft)"
      />
    </template>
    <edit-building-details :building="building" :project-city="projectCity" :project-id="projectId" :project-type="projectType" />
    <edit-building-sales :building="building" :project-type="projectType" :contractor-name="contractorName" />
    <edit-building-construction-site :building="building" />
    <edit-building-technical-features :technical-features="building.technicalFeatures" />
  </form-with-steps>
</template>

<script>
import FormWithSteps from '@/components/molecules/FormWithSteps'
import ButtonGcr from '@/components/atoms/Button'
import { Consts, Urls } from '@/_helpers'
import alerts from '@/_helpers/alerts'
import getProjectBuilding from '@/graphql/accreditations/projects/getProjectBuilding.graphql'
import getProjectAndDraft from '@/graphql/accreditations/projects/getProjectAndDraft.graphql'
import saveBuilding from '@/graphql/accreditations/projects/saveBuilding.graphql'
import formDirty from '@/components/mixins/form-dirty'
import EditBuildingDetails from '@/components/building/edit/EditBuildingDetails'
import EditBuildingSales from '@/components/building/edit/EditBuildingSales'
import EditBuildingConstructionSite from '@/components/building/edit/EditBuildingConstructionSite'
import EditBuildingTechnicalFeatures from '@/components/building/edit/EditBuildingTechnicalFeatures'
import Building from '@/_models/Building'
import { applyScopeOfWork } from '@/_models/technical_features'
import { mapGetters } from 'vuex'

export default {
  components: {
    EditBuildingDetails,
    EditBuildingSales,
    EditBuildingConstructionSite,
    EditBuildingTechnicalFeatures,
    FormWithSteps,
    ButtonGcr
  },
  mixins: [formDirty],
  props: {
    projectId: String,
    buildingId: {
      type: String,
      default: null
    },
    copy: Boolean,
    submitText: String,
    title: String,
    handleSubmit: Function
  },
  data() {
    return {
      building: Building.newDraft(),
      projectCity: null,
      projectType: null,
      contractorName: null,
      submitting: false
    }
  },
  apollo: {
    getProjectBuilding: {
      query: getProjectBuilding,
      variables() {
        return {
          accreditationId: this.accreditationId,
          projectId: this.projectId,
          buildingId: this.buildingId
        }
      },
      skip() {
        return !this.buildingId
      },
      update(data) {
        let project = data.accreditation.project
        this.contractorName = data.accreditation.company.name
        this.projectCity = project.city
        this.projectType = project.type

        this.building = Building.fromGraphql(project.building)
        if (this.copy) this.building = Building.copyOf(this.building)
      }
    },
    getProjectAndDraft: {
      query: getProjectAndDraft,
      variables() {
        return {
          accreditationId: this.accreditationId,
          projectId: this.projectId
        }
      },
      skip() {
        return this.buildingId
      },
      update(data) {
        let project = data.accreditation.project
        this.contractorName = data.accreditation.company.name
        this.projectCity = project.city
        this.projectType = project.type

        this.building = Building.newFor(project)
      }
    }
  },
  computed: {
    ...mapGetters({
      accreditationId: Consts.GETTER_ACCREDITATION_CURRENT_ID,
      isReadOnly: Consts.GETTER_USER_ISSAC
    }),
    projectUrl() {
      return {
        name: Urls.PROJECT_DETAILS,
        query: { id: this.projectId }
      }
    }
  },
  methods: {
    async saveBuildingDraft() {
      await this.saveBuilding(true)
      alerts.showInfo('saveBuildingDraftSuccess')
      this.redirectToProjectDetails(this.projectId)
    },
    async saveAndSubmit() {
      await this.saveBuilding(false)
      const projectId = await this.handleSubmit()
      this.redirectToProjectDetails(projectId)
    },
    saveBuilding(isDraft) {
      return this.$apollo
        .mutate({
          mutation: saveBuilding,
          variables: {
            input: {
              accreditationId: this.accreditationId,
              projectId: this.projectId,
              isDraft,
              ...Building.toGraphql(this.building)
            }
          }
        })
    },
    redirectToProjectDetails(projectId) {
      this.dirty = false
      this.$router.push({
        name: Urls.PROJECT_DETAILS,
        query: { id: projectId }
      })
    },
    async submit(action) {
      try {
        this.submitting = true
        await action()
      } finally {
        this.submitting = false
      }
    }
  },
  watch: {
    'building.sale.scopeOfWork': {
      deep: true,
      handler(newValue) {
        applyScopeOfWork(this.building.technicalFeatures, newValue)
      }
    }
  }
}
</script>

<style lang="scss">
.invalid-feedback {
  display: none;
}

.was-validated .invalid-feedback {
  display: block;
}
</style>
