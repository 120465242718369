export function convertToCondo(dwellingUnit) {
  dwellingUnit.sale ||= {
    price: null,
    hasKnownBeneficiary: false,
    contractNumber: null,
    plannedDeliveryDate: null
  }
}

export function convertToApartment(dwellingUnit) {
  dwellingUnit.lotNumber = null
  dwellingUnit.sale = null
}

export function anEmptyDwellingUnit() {
  return {
    lotNumber: null,
    buildingNumber: null,
    apartment: null,
    street: null,
    postalCode: null,
    sale: null
  }
}
